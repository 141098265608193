import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import Logo from "../Assets/Images/mQuiz-logo.png";
import "../Styles/Header.css";
import avatar from "../Assets/Images/avatar.webp";
import Vector from "../Assets/Images/Vector.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { academyLogout, userLogout } from "../apiClient";
import { toast } from "react-toastify";

const Header = ({ param }) => {
  const [quizHighlight, setQuizHighlight] = useState(true);
  const [reportHighlight, setReportHighlight] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("UserRole"));
  const myRole = JSON.parse(localStorage.getItem("roleName"));

  const navigate = useNavigate();
  const location = useLocation();

  const highlightReport = () => {
    setQuizHighlight(false);
    setReportHighlight(true);
  };

  const highlightQuiz = () => {
    setReportHighlight(false);
    setQuizHighlight(true);
  };

  const showQuizReport = (id) => {
    navigate(`/academy/quiz/report/${id}`);
  };

  // const hideQuizReport = () => {
  //   displayReport(false);
  // };

  const logoutFunc = () => {
    if (myRole === "User") {
      userLogout()
        .then((res) => {
          if (res.status === 200) {
            window.localStorage.removeItem("UserToken");
            window.localStorage.removeItem("roleName");
            navigate("/");
          }
        })
        .catch((err) => toast.error(err.message));
    } else {
      academyLogout()
        .then((res) => {
          if (res.status === 200) {
            window.localStorage.removeItem("UserData");
            navigate("/");
          }
        })
        .catch((err) => toast.error(err.message));
    }
  };

  return (
    <Navbar bg="white" data-bs-theme="light">
      <Row md={12} style={{ width: "100%" }}>
        <Col md={5}>
          <Navbar.Brand href="#home" className="brandLogo">
            <img src={Logo} className="App-logo" alt="logo" />
            <h3>MagmaQuiz</h3>
          </Navbar.Brand>
        </Col>
        <Col md={5} style={{ paddingTop: "14px" }}>
          <Nav className="me-auto">
            {myRole === "User" ? (
              <Nav.Link
                // onClick={highlightQuiz}
                className="academyQuizLink"
                href="/user/dashboard"
                // style={{ borderBottom: quizHighlight ? "6px solid #E3AB06" : "" }}
              >
                Dashboard
              </Nav.Link>
            ) : (
              <>
                <Nav.Link
                  // onClick={highlightQuiz}
                  className="academyQuizLink"
                  href="/academy/dashboard"
                  // style={{ borderBottom: quizHighlight ? "6px solid #E3AB06" : "" }}
                >
                  Quiz
                </Nav.Link>
                <Nav.Link
                  className="academyQuizLink"
                  href={
                    param
                      ? `/academy/quiz/report/${param}`
                      : `/academy/quiz/report`
                  }
                  // onClick={() => highlightReport()}
                  // style={{ borderBottom: reportHighlight ? "6px solid #E3AB06" : "", position: "relative", left: "20px" }}
                  style={{ position: "relative", left: "20px" }}
                >
                  Report
                </Nav.Link>
              </>
            )}
          </Nav>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              className="profileDropD"
              style={{ background: "transparent" }}
            >
              <img src={avatar} className="profileImg" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-2">{userDetails}</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => logoutFunc()}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <img src={Vector} className="profileVector" />
          <img src={avatar} className="profileImg" />
          <DropdownButton variant="dark" id="dropdown-basic-button">
            <Dropdown.Item onClick={() => logoutFunc()}>Logout</Dropdown.Item>
          </DropdownButton> */}
        </Col>
      </Row>
    </Navbar>
  );
};

export default Header;
