import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { getAcademyList } from '../../apiClient'
import kebab from '../../Assets/Images/kebab.png'

const UsersTable = () => {

    // const [tableData, setTableData] = useState({})

    // useEffect(() => {
    //     getAcademyData()
    // }, [!isLoadTable])

    // const getAcademyData = () => {
    //     const dataToPost = {
    //         searchText: ""
    //     }

    //     getAcademyList(dataToPost)
    //         .then((res) => {
    //             setTableData(res.data)
    //         })
    // }

    return (
        <Table responsive="sm">
            <thead>
                <tr>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>Academy Name</th>
                    <th>Quiz Attempts</th>
                    <th>Quiz Passes</th>
                    <th></th>
                </tr>
            </thead>
            {/* <tbody>
                {tableData?.data?.length > 0 && tableData?.data?.map((academy, indx) => {
                    return (<tr>
                        <td>{academy?.academyName}</td>
                        <td>{academy?.name}</td>
                        <td>{academy?.designation}</td>
                        <td>{academy?.emailId}</td>
                        <td>{academy?.mobileNumber}</td>
                        <td>
                            <img src={kebab} />
                        </td>
                    </tr>)
                })}
            </tbody> */}
        </Table>
    )
}

export default UsersTable