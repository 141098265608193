import React, { useState } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../Assets/Images/mQuiz-logo.png";
import "../../Styles/Header.css";
import avatar from '../../Assets/Images/avatar.jpg'
import Vector from "../../Assets/Images/Vector.png";

const AdminHeader = () => {

    // const [showAcademy, setShowAcademy] = useState(true)
    // const [showUsers, setShowUsers] = useState(false)

    // const showAcademyData = () => {
    //     setShowAcademy(true)
    //     setShowUsers(false)
    // }

    // const showUsersData = () => {
    //     setShowAcademy(false)
    //     setShowUsers(true)
    // }

    return (
        <>
            <Navbar bg="white" data-bs-theme="light">
                <Row md={12} style={{ width: "100%" }}>
                    <Col md={3}>
                        <Navbar.Brand href="#home" className="brandLogo">
                            <img src={Logo} className="App-logo" alt="logo" />
                            <h3>MagmaQuiz</h3>
                        </Navbar.Brand>
                    </Col>
                    <Col md={7} style={{ paddingTop: "14px" }}>
                        <Nav className="me-auto">
                            <Nav.Link
                                className="academyQuizLink"
                            // href="#home"
                            >
                                Academy
                            </Nav.Link>
                            <Nav.Link
                                className="academyQuizLink"
                            >
                                Users
                            </Nav.Link>
                            <Nav.Link
                                className="academyQuizLink"
                            >
                                Quiz
                            </Nav.Link>
                            <Nav.Link
                                className="academyQuizLink"
                            // href="#features"
                            //   onClick={showQuizReport}
                            >
                                Report
                            </Nav.Link>
                        </Nav>
                    </Col>
                    <Col md={2} style={{ justifyContent: "space-between" }}>
                        <img src={Vector} className="profileVector" />
                        <img src={avatar} className="profileImg" />
                    </Col>
                </Row>
            </Navbar>
        </>
    );
};

export default AdminHeader;
