import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Row, Col, Card, Button, Form } from "react-bootstrap";
import "../../Styles/academyQuiz.css";
import Vector from "../../Assets/Images/Vector.png";
import Logo from "../../Assets/Images/mQuiz-logo.png";
import questNotAdded from "../../Assets/Images/questNotAdded.png";
import correctAnsTick from "../../Assets/Images/correctAnsTick.png";
import Delete from "../../Assets/Images/Delete.svg";
import AddQuestions from "./AddQuestions";
import Header from "../Header";
import { quizInfo, questionsList, addQuestionExcel, publishQuizData, deleteQuestionsData } from "../../apiClient";
import { useParams } from "react-router-dom";
import time from "../../Assets/Images/time.png";
import { ToastContainer, toast } from "react-toastify";
import edit from "../../Assets/Images/edit.svg";
import DeleteConfirmation from "../Shared/DeleteConfirmation";

function QuizQuestionSet() {
  const hiddenFileInput = useRef(null);

  const [isShowAddQuestions, setIsShowAddQuestions] = useState(false);
  const [quizInfoData, setQuizInfoData] = useState({});
  const [quizQuestionList, setQuizQuestionList] = useState({});
  const [codeCopied, setCodeCopied] = useState(false);
  const [showEditQuestion, setShowEditQuestion] = useState(false)
  const [questionIndex, setQuestionIndex] = useState('')
  const [changeStatusToPublished, setChangeStatusToPublished] = useState(false)
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [questionId, setQuestionId] = useState('')

  const param = useParams();

  useEffect(() => {
    showQuizDetails(param);
    questionDetails(param);
  }, []);

  useEffect(() => {
    if(confirmDelete){
      deleteQuestionSet(questionId)
    }
  }, [confirmDelete])

  const notify = () => toast("Code copied to clipboard!");

  const showQuizDetails = (param) => {
    quizInfo(param.id)
    .then((res) => setQuizInfoData(res.data.data))
    .catch((err) => toast.error(err));
  };

  const loadAndCloseModal = () => {
    setIsShowAddQuestions(false);
    questionDetails(param);
  };

  const closeModal = () => {
    setIsShowAddQuestions(false);
  };

  const closeAndLoadEditModal = () => {
    setShowEditQuestion(false)
    questionDetails(param);
  }

  const questionDetails = (param) => {
    const dataToPost = {
      quizId: param.id,
    };

    questionsList(dataToPost)
    .then((res) => {
      setQuizQuestionList(res.data)
      });
  };

  const copyQuizCode = (quizList) => {
    setCodeCopied(true);
    notify();
    navigator.clipboard.writeText(quizList?.shareCode);

    setTimeout(() => {
      setCodeCopied(false);
    }, 1000);
  };

  const importExcelFunction = (event) => {
    hiddenFileInput.current.click();
  };

  const handleExcelChange = (event) => {
    const fileUploaded = event.target.files[0];
    const data = {
      quizId: param.id,
      attachmentFile: fileUploaded,
    };
    addQuestionExcel(data)
      .then((res) => {
        questionDetails(param);
        toast.success("Question added successfully");
      })
      .catch((err) => toast.error(err));
    // handleFile(fileUploaded);                   // ADDED
  };

  const openEditQuestion = (id) => {
    setQuestionIndex(id)
    setShowEditQuestion(true)
  }

  const deleteQuestion = (id, questionid) => {
    setQuestionId(questionid)
    setQuestionIndex(id)
    setShowDeleteQuestion(true)
  }

  const deleteQuestionSet = () => {
    deleteQuestionsData(questionId)
    .then((res) => {
      questionDetails(param);
      toast.success("Question deleted successfully");
      setConfirmDelete(false)
    })
    .catch((err) => toast.error(err));
  }

  const publishQuiz = () => {
    publishQuizData(param.id, "true")
    .then((res) => {
      setChangeStatusToPublished(true)
      toast.success("Quiz Published successfully");
    })
    .catch((err) => toast.error(err));
  }

  const deleteConfirmed = (flag) => {
    setConfirmDelete(flag)
  }

  return (
    <>
      <Header param={param.id} />
      <div style={{ padding: "20px", backgroundColor: "#F6F6F6" }}>
        <Row md={12}>
          <Col md={4}>
            <Dropdown className="quizDropdown">
              <Dropdown.Toggle>All Quiz</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Option 1</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Option 3</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={6}>
            <div className="searchContainer">
              <img src={Vector} className="App-logo" alt="Vector" />
              <input
                id="searchInput"
                type="text"
                placeholder="Search for Quiz name"
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="searchButton">
              <button type="submit" onClick={() => setIsShowAddQuestions(true)}>
                <b>+ Add Questions</b>
              </button>
            </div>
          </Col>
        </Row>
        {isShowAddQuestions && (
          <AddQuestions
            show={isShowAddQuestions}
            close={closeModal}
            quizId={param.id}
            loadAndCloseModal={loadAndCloseModal}
          />
        )}
      </div>
      <div className="quizQuestionSetDetails">
        <Card>
          <Card.Body>
            <div>
              <Row md={12}>
                <Col md={4}>
                  <Card.Img
                    variant="top"
                    src={quizInfoData?.image}
                    style={{
                      height: "133px",
                      width: "299px",
                      borderRadius: "10px",
                    }}
                  />
                </Col>
                <Col md={8}>
                  {/* <div className="timingAndName">
                    <span>
                      <img src={time} style={{ height: "12px", width: "12px" }} />
                    </span>
                    <h6>{quizInfoData?.timing ? quizInfoData?.timing : '-'}</h6>
                  </div> */}
                  <div className="timingAndName">
                    <h6>{quizInfoData?.name}</h6>
                  </div>
                  {quizInfoData?.type == "PRIVATE" && (
                    <div className="quizDetailsCardButton">
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "black" }}
                        onClick={() => copyQuizCode(quizInfoData)}
                      >
                        {codeCopied ? "Copied" : "Share code"}
                      </Button>
                      <Button
                        variant="primary"
                        style={{
                          backgroundColor: "#22A37A",
                          marginLeft: "20px",
                        }}
                        onClick={publishQuiz}
                      >
                        {changeStatusToPublished ? "Published" : "Publish"}
                      </Button>
                      <Button
                        variant="primary"
                        style={{
                          backgroundColor: "#6b58bf",
                          marginLeft: "20px",
                        }}
                        onClick={importExcelFunction}
                      >
                        Import Excel
                      </Button>
                      <input
                        type="file"
                        onChange={handleExcelChange}
                        ref={hiddenFileInput}
                        hidden
                      />

                      {/* <Form.Control type="file" size="lg" /> */}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </div>
      {quizQuestionList?.data == null ? (
        <div className="quizNotAdded">
          <div className="quizNotAddedDetails">
            <div className="quizNotAddedImg">
              <img
                src={questNotAdded}
                className="App-logo"
                alt="questNotAdded"
              />
            </div>
            <div className="quizNotAddedText">
              <h6>Questions not yet added</h6>
            </div>
          </div>
        </div>
      ) : (
        <div className="quizListCards">
          <Row>
            {quizQuestionList?.data?.map((question, indx) => {
              return (
                <Col md="3">
                  <Card
                    style={{
                      maxHeight: "384px",
                      width: "280px",
                      height: "auto",
                      borderRadius: "5%",
                      cursor: "pointer",
                      padding: "5px 20px 20px 20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                      }}
                    >
                      <img
                        style={{
                          zIndex: "1",
                          position: "relative",
                          float: "right",
                        }}
                        src={edit}
                        onClick={() => openEditQuestion(indx)}
                      />
                      <img
                        style={{
                          zIndex: "1",
                          position: "relative",
                          float: "right",
                        }}
                        src={Delete}
                        onClick={() => deleteQuestion(indx, question?.id)}
                      />
                    </div>
                    {question?.attachment !== null &&
                      (question?.typeOfQuestion == "photo" ? (
                        <img
                          src={question?.attachment}
                          style={{
                            minHeight: "50%",
                            minWidth: "100%",
                            position: "relative",
                            bottom: "25px",
                          }}
                        ></img>
                      ) : (
                        <video width="237" height="133" controls>
                          <source src={question?.attachment} type="video/mp4" />
                        </video>
                      ))}
                    <Card.Body>
                      <Card.Text className="questionTextStyle">
                        {question?.question}
                      </Card.Text>
                      <div className="correctAnswerSection">
                        <span>
                          <img
                            src={correctAnsTick}
                            className="App-logo"
                            alt="correctAnsTick"
                            style={{ height: "24px", width: "24px" }}
                          />
                        </span>
                        <span style={{ marginLeft: "13px" }}>
                          {question?.answer}
                        </span>
                      </div>
                      <div className="marksSection">
                        <div className="correctSection">
                          <span className="marksSectionText">
                            Correct Marks
                          </span>
                          <span style={{ position: "relative", left: "13px" }}>
                            {question?.correctMarks}
                          </span>
                        </div>
                        <div className="inCorrectSection">
                          <span className="marksSectionText">
                            Incorrect Marks
                          </span>
                          <span style={{ position: "relative", left: "13px" }}>
                            {question?.incorrectMarks}
                          </span>
                        </div>
                      </div>
                      {questionIndex == indx && showEditQuestion && (
                        <AddQuestions
                          show={showEditQuestion}
                          close={() => setShowEditQuestion(false)}
                          questionId={question?.id}
                          quizId={param.id}
                          closeAndLoadEditModal={closeAndLoadEditModal}
                        />
                      )}
                      {questionIndex == indx && showDeleteQuestion && (
                        <DeleteConfirmation
                          show={DeleteConfirmation}
                          onHide={() => setShowDeleteQuestion(false)}
                          deleteConfirmed={deleteConfirmed}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </>
  );
}

export default QuizQuestionSet;
