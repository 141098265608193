import React, { useState, useEffect } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardFooter from "../Dashboard/DashboardFooter";
import UserQuizQuestion from "./UserQuizQuestion";
import UserQuizAnswer from "./UserQuizAnswer";
import { Container } from "react-bootstrap";

const UserQuiz = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  let showCorrectAnswer = JSON.parse(
    window.sessionStorage.getItem("showAnswer")
  );
  let myData = JSON.parse(window.sessionStorage.getItem("MyData"));

  const [showAnswer, setShowAnswer] = useState(showCorrectAnswer);

  useEffect(() => {
    setShowAnswer(showAnswer);
  }, [showAnswer]);

  return (
    <>
      <DashboardHeader />
      <Container fluid className="user-dashboard-bg">
        <div className="user-qz-row-1">
          <div className="user-qz-row-col-1">
            <img src={myData?.image} height="135px" />
            <p className="rl-rw-cl-text-1">{myData?.name}</p>
            <p className="user-qz-timer-text-2">{myData?.timing}</p>
          </div>
          {showAnswer ? (
            <UserQuizAnswer
              setShowAnswer={setShowAnswer}
              showAnswer={showAnswer}
            />
          ) : (
            <UserQuizQuestion setShowAnswer={setShowAnswer} />
          )}
        </div>
      </Container>
      <DashboardFooter />
    </>
  );
};

export default UserQuiz;
