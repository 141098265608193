import React, { useState } from "react";
import facebook from "../../Assets/Images/facebook.png";
import goggle from "../../Assets/Images/goggle.png";
import passwordHide from "../../Assets/Images/passwordHide.png";
import passwordView from "../../Assets/Images/passwordView.png";
import { Button } from "react-bootstrap/esm";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../Styles/HomePage.css";
import { baseurl } from "../../apiClient";

const UserSignup = (props) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = useState({
    emailId: "",
    password: "",
    name: "",
    companyName: "",
    designation: "",
    location: "",
  });

  const visiblePassword = (value) => {
    setShowPassword(value);
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${baseurl}/userSignUp`, user, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status.status === 200) {
          props.setShow(false);
          toast.success("Sign Up successfull");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      {/* <div className="signup-buttons">
        <Button className="sign-up-g-button">
          <img src={goggle} height="20px" alt="logo" />
          Continue With Google
        </Button>
        <Button className="sign-up-f-button">
          <img src={facebook} height="20px" alt="logo" />
          Continue With Facebook
        </Button>
      </div>
      <p className="sign-up-text-1">Or email</p> */}
      <form className="homepage-modal-form" onSubmit={(e) => handleSubmit(e)}>
        <label>Name</label>
        <br />
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Enter Your Name"
          className="form-input-1"
          onChange={(e) => handleChange(e)}
        />
        <br />
        <label>Email</label>
        <br />
        <input
          type="email"
          id="emailId"
          name="emailId"
          value={user.emailId}
          placeholder="Enter Your Email"
          className="form-input-1"
          onChange={(e) => handleChange(e)}
          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
          required
        />
        <br />
        <label>Password</label>
        {/* <br /> */}
        <div className="password-visible-hide">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={user.password}
            title="Must contain at least one number, one special character one uppercase, one lowercase letter and at least 8 or more characters"
            required
            placeholder="Enter Password"
            className="form-input-1"
            onChange={(e) => handleChange(e)}
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          />
          <span className="password-images">
            <img
              src={passwordView}
              height="18px"
              width="28px"
              onClick={() => visiblePassword(true)}
            />
            <img
              src={passwordHide}
              height="22px"
              width="28px"
              onClick={() => visiblePassword(false)}
            />
          </span>
        </div>

        <label>Company Name</label>
        <br />
        <input
          type="text"
          id="companyName"
          name="companyName"
          value={user.companyName}
          required
          placeholder="Enter Company Name"
          className="form-input-1"
          onChange={(e) => handleChange(e)}
        />
        <br />
        <label>Designation</label>
        <br />
        <input
          type="text"
          id="designation"
          name="designation"
          value={user.designation}
          required
          placeholder="Enter Designation"
          className="form-input-1"
          onChange={(e) => handleChange(e)}
        />
        <br />

        <label>Location</label>
        <br />
        <input
          type="text"
          id="location"
          name="location"
          placeholder="Enter Location"
          value={user.location}
          className="form-input-1"
          required
          onChange={(e) => handleChange(e)}
        />
        <br />
        <Button type="submit" className="sign-up-button">
          Sign up
        </Button>
      </form>
    </>
  );
};

export default UserSignup;
