import React from 'react'
import { Dropdown, Row, Col, Card, Button } from "react-bootstrap";
import Vector from '../../Assets/Images/Vector.png'
import '../../Styles/AdminDashboard.css'
import AddAcademy from './AddAcademy';
import { useState } from 'react';
import AcademyTable from './AcademyTable'
import UsersTable from './UsersTable';


const AdminAcademy = (props) => {
    const [isShowAddAcademy, setIsShowAddAcademy] = useState(false)
    const [isLoadTable, setIsLoadTable] = useState(false)

    const handleAcademyModal = () => {
        setIsShowAddAcademy(true)
    }

    const closeAndLoad = () => {
        setIsShowAddAcademy(false)
        setIsLoadTable(true)
    }
    return (
        <>
            <Row md={12}>
                <Col md={10}>
                    <div className="searchContainer">
                        <img src={Vector} className="App-logo" alt="Vector" />
                        <input
                            id="searchInput"
                            type="text"
                            placeholder="Search for Academy, name, email, phone"
                        />
                    </div>
                </Col>
                <Col md={2}>
                    <div className="searchButton">
                        <button type="submit" onClick={handleAcademyModal}>
                            <b>+ Add Academy</b>
                        </button>
                    </div>
                </Col>
                {isShowAddAcademy && (
                    <AddAcademy show={isShowAddAcademy} close={closeAndLoad} />
                )}
            </Row>
            <Row md={12} className='academyTable'>
                <Col md={12}>
                    {<AcademyTable isLoadTable={isShowAddAcademy} />}
                    {/* {showUsers && <UsersTable />} */}
                </Col>
            </Row>
        </>
    )
}

export default AdminAcademy