import React, { useState } from "react";
import AdminHeader from "./AdminHeader";
import AdminAcademy from './Academy'
import '../../Styles/AdminDashboard.css'
import { Outlet } from "react-router-dom";

function AdminDashboard() {
    const [isShowReport, setIsShowReport] = useState(false);

    const displayReport = (data) => {
        setIsShowReport(data);
    };

    // const showUsersData = (data) => {
    //     setShowUsers(data)
    //     setShowAcademy(data)
    // }

    return (
        <>
            <AdminHeader />
            <div className="dashboardContent">
                <AdminAcademy />
            </div>

        </>
    );
}

export default AdminDashboard;
