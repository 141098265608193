import { useState, useEffect, useParams } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import closeBtn from "../../Assets/Images/closeBtn.png";
import addQuizLogo from "../../Assets/Images/addQuizLogo.png";
import attachFile from "../../Assets/Images/attachFile.png";
import tick from "../../Assets/Images/check.png";
import cross from "../../Assets/Images/close.png";
import deleteAttach from "../../Assets/Images/deleteAttach.png";
import { saveQuestions, getQuestionsData } from "../../apiClient";
import axios from "axios";
import { toast } from "react-toastify";
import "../../Styles/academyQuiz.css";

function AddQuestions(props) {
  const {
    questionId,
    quizId,
    close,
    closeEdit,
    loadAndCloseModal,
    closeAndLoadEditModal,
  } = props;
  const [isAnswerData, setIsAnswerData] = useState("");
  const [isQuestionData, setIsQuestionData] = useState("");
  const [isAnswerList, setIsAnswerList] = useState([]);
  const [mediaType, setMediaType] = useState("");
  const [selectionType, setSelectionType] = useState("");
  const [correctMarks, setCorrectMarks] = useState("");
  const [wrongMarks, setWrongMarks] = useState("");
  const [isFileData, setIsFileData] = useState(null);
  const [quizTimeMinutes, setQuizTimeMinutes] = useState("0");
  const [quizTimeSeconds, setQuizTimeSeconds] = useState("0");
  const [correctAnsRadio, setCorrectAnsRadio] = useState(false);
  const [showUploadedFile, setShowUploadedFile] = useState({});
  const [minuteData, setMinuteData] = useState(0);
  const [secondData, setSecondData] = useState(0);
  const [defaultMinute, setDefaultMinute] = useState('')
  const [defaultSecond, setDefaultSecond] = useState('')  
  const [questionDataToEdit, setQuestionDataToEdit] = useState({
    id: "",
    quizId: "",
    typeOfQuestion: "",
    typeOfAnswer: "",
    question: "",
    correctMarks: "",
    incorrectMarks: "",
    answers: [],
    timing: null,
    timingMinute: minuteData,
    timingSecond: secondData,
    media: null,
  });

  useEffect(() => {
    if (questionId) {
      getQuizQuestionsDetails();
      setSplitTimer()
    }
  }, [questionId]);

  useEffect(() => {
    if((questionDataToEdit.typeOfQuestion == "photo" && questionDataToEdit?.media?.size > 500000) || (questionDataToEdit.typeOfQuestion == "video" && questionDataToEdit?.media?.size > 5000000))
    checkFileSize()
  }, [questionDataToEdit?.media?.size])

  const getQuizQuestionsDetails = () => {
    getQuestionsData(questionId)
    .then((res) => {
      setQuestionDataToEdit(res.data?.data);
      setIsAnswerList(res.data?.data?.answers)
      // setDefaultMinute(res.data?.data.timing[0]);
      // setDefaultSecond(res.data?.data.timing[2]);
    })
    .catch((err) => toast.error(err.message));
  };

  const minutes = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
    { value: 32, label: 32 },
    { value: 33, label: 33 },
    { value: 34, label: 34 },
    { value: 35, label: 35 },
    { value: 36, label: 36 },
    { value: 37, label: 37 },
    { value: 38, label: 38 },
    { value: 39, label: 39 },
    { value: 40, label: 40 },
    { value: 41, label: 41 },
    { value: 42, label: 42 },
    { value: 43, label: 43 },
    { value: 44, label: 44 },
    { value: 45, label: 45 },
    { value: 46, label: 46 },
    { value: 47, label: 47 },
    { value: 48, label: 48 },
    { value: 49, label: 49 },
    { value: 50, label: 50 },
    { value: 51, label: 51 },
    { value: 52, label: 52 },
    { value: 53, label: 53 },
    { value: 54, label: 54 },
    { value: 55, label: 55 },
    { value: 56, label: 56 },
    { value: 57, label: 57 },
    { value: 58, label: 58 },
    { value: 59, label: 59 },
    { value: 60, label: 60 },
  ];

  const seconds = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
    { value: 32, label: 32 },
    { value: 33, label: 33 },
    { value: 34, label: 34 },
    { value: 35, label: 35 },
    { value: 36, label: 36 },
    { value: 37, label: 37 },
    { value: 38, label: 38 },
    { value: 39, label: 39 },
    { value: 40, label: 40 },
    { value: 41, label: 41 },
    { value: 42, label: 42 },
    { value: 43, label: 43 },
    { value: 44, label: 44 },
    { value: 45, label: 45 },
    { value: 46, label: 46 },
    { value: 47, label: 47 },
    { value: 48, label: 48 },
    { value: 49, label: 49 },
    { value: 50, label: 50 },
    { value: 51, label: 51 },
    { value: 52, label: 52 },
    { value: 53, label: 53 },
    { value: 54, label: 54 },
    { value: 55, label: 55 },
    { value: 56, label: 56 },
    { value: 57, label: 57 },
    { value: 58, label: 58 },
    { value: 59, label: 59 },
    { value: 60, label: 60 },
  ];

  const handleQuizTimeMinutes = (e) => {
    setQuizTimeMinutes(e.target.value);
  };

  const handleQuizTimeSeconds = (e) => {
    setQuizTimeSeconds(e.target.value);
  };

  const closeModalWithoutReload = () => {
    close();
  };

  const handleQuestion = (e) => {
    setIsQuestionData(e.target.value);
  };

  const handleAnswers = (e) => {
    setIsAnswerData(e.target.value);
  };

  const clearInput = () => {
    setIsAnswerData("");
  };

  const handleMediaType = (e) => {
    setMediaType(e.target.value);
  };

  const handleSelectionType = (e) => {
    setSelectionType(e.target.value);
  };

  const handleCorrectMarks = (e) => {
    setCorrectMarks(e.target.value);
  };

  const handleWrongMarks = (e) => {
    setWrongMarks(e.target.value);
  };

  const handleFiles = (e) => {
    setIsFileData(e.target.files[0]);
  };

  const saveAnswers = () => {
    let newAnswerSet = [...isAnswerList];
    const answerObj = {
      id: isAnswerList.length + 1,
      indexValue: isAnswerList.length + 1,
      answer: isAnswerData,
      correctAns: false,
    };

    newAnswerSet = [...newAnswerSet, answerObj];
    setIsAnswerList(newAnswerSet);
    setIsAnswerData("");
  };

  const deleteItem = (id) => {
    const updatedItem = isAnswerList?.filter((answer, indx) => {
      return answer?.id !== id;
    });
    setIsAnswerList(updatedItem);
  };

  const saveQuestionSet = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("typeOfQuestion", questionDataToEdit?.typeOfQuestion);
    questionId && bodyFormData.append("id", questionId);
    bodyFormData.append("quizId", quizId);
    bodyFormData.append("typeOfAnswer", questionDataToEdit?.typeOfAnswer);
    bodyFormData.append("question", questionDataToEdit?.question);
    bodyFormData.append("correctMarks", questionDataToEdit?.correctMarks);
    bodyFormData.append("incorrectMarks", questionDataToEdit?.incorrectMarks);
    bodyFormData.append("timing", (minuteData || secondData) ?  `${minuteData}:${secondData}` : questionDataToEdit?.timing)
   

    // bodyFormData.append(
    //   "timing",
    //   `${
    //     questionDataToEdit?.timing
    //       ? questionDataToEdit?.timing[0]
    //       : questionDataToEdit?.timingMinute
    //   }:${
    //     questionDataToEdit?.timing
    //       ? questionDataToEdit?.timing[2]
    //       : questionDataToEdit?.timingSecond
    //   }`
    // );
    questionDataToEdit?.media ?
      bodyFormData.append("attachmentFile", questionDataToEdit?.media)
      : bodyFormData.append("attachmentFile", questionDataToEdit?.attachment)
    bodyFormData.append("answers", JSON.stringify(isAnswerList));
    saveQuestions(bodyFormData)
      .then((res) => {
        questionId ? closeAndLoadEditModal() : loadAndCloseModal();
      })
      .catch((err) => toast.error(err.message));
  };

  const markCorrectAnswer = (x, id) => {
    isAnswerList.map((answer) => {
      if (answer.id === id) {
        answer.correctAns = true;
        setCorrectAnsRadio(true);
      } else {
        answer.correctAns = false;
      }
    });
  };

  const setSplitTimer = () => {
    let hms = questionDataToEdit?.timing
    let splitTiming = hms?.split(':')
    let splitTimingMinute = splitTiming && splitTiming[0]
    let splitTimingSecond = splitTiming && splitTiming[1]
  
    setDefaultMinute(splitTimingMinute)
    setDefaultSecond(splitTimingSecond)
  }

  const setQuestionMinuteTimer = (e) => {
    setMinuteData(e.target.value)
   }

 const setQuestionSecondTimer = (e) => {
  setSecondData(e.target.value)
 }

 const checkFileSize = () => {
  if(questionDataToEdit?.typeOfQuestion == "video" && questionDataToEdit?.media?.size > 5000000) {
    toast("Video size should be less than 5MB")
    setQuestionDataToEdit({
      ...questionDataToEdit,
      media: null,
    })
  }

  if(questionDataToEdit?.typeOfQuestion == "photo" && questionDataToEdit?.media?.size > 500000) {
    toast("Image size should be less than 500KB")
    setQuestionDataToEdit({
      ...questionDataToEdit,
      media: null,
    })
  }
 }

  return (
    <>
      <Modal
        scrollable={true}
        {...props}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="addQuizModal"
      >
        <Modal.Body className="addQuestModalBody">
          <div className="closeBtn">
            <span className="closeButtonOnHeader">
              <img
                src={closeBtn}
                alt="closeBtn"
                onClick={closeModalWithoutReload}
              />
            </span>
          </div>
          <div className="container">
            <div className="questionHeader">
              <img src={addQuizLogo} className="App-logo" alt="addQuizLogo" />
              <span>Add Questions</span>
            </div>

            <div className="addQuestionsForm">
              <h6>Type of Questions</h6>
              <div className="typeCheckbox">
                <Row md={12}>
                  <Form>
                    <div key="inline-radio" className="mb-3">
                      <Form.Check
                        inline
                        label="Text"
                        name="group1"
                        type="radio"
                        onChange={(e) =>
                          setQuestionDataToEdit({
                            ...questionDataToEdit,
                            typeOfQuestion: e.target.value,
                          })
                        }
                        value="text"
                        id="inline-radio-1"
                        checked={questionDataToEdit?.typeOfQuestion == "text"}
                      />
                      <Form.Check
                        inline
                        label="Video"
                        name="group1"
                        type="radio"
                        onChange={(e) =>
                          setQuestionDataToEdit({
                            ...questionDataToEdit,
                            typeOfQuestion: e.target.value,
                          })
                        }
                        value="video"
                        id="inline-radio-2"
                        style={{ marginLeft: "20px" }}
                        checked={questionDataToEdit?.typeOfQuestion == "video"}
                      />
                      <Form.Check
                        inline
                        label="Photo"
                        name="group1"
                        type="radio"
                        onChange={(e) =>
                          setQuestionDataToEdit({
                            ...questionDataToEdit,
                            typeOfQuestion: e.target.value,
                          })
                        }
                        value="photo"
                        id="inline-radio-3"
                        style={{ marginLeft: "20px" }}
                        checked={questionDataToEdit?.typeOfQuestion == "photo"}
                      />
                    </div>
                  </Form>
                </Row>
              </div>
            </div>
            <div className="addQuestionsForm">
              <h6>Type of Answer</h6>
              <div className="typeCheckbox">
                <Row md={12}>
                  <Form>
                    <div
                      key="inline-radio"
                      className="mb-3"
                      style={{ width: "441px" }}
                    >
                      <Form.Check
                        inline
                        label="Single Choice"
                        name="group1"
                        type="radio"
                        value="single choice"
                        id="inline-radio-1"
                        onChange={(e) =>
                          setQuestionDataToEdit({
                            ...questionDataToEdit,
                            typeOfAnswer: e.target.value,
                          })
                        }
                        checked={
                          questionDataToEdit?.typeOfAnswer == "single choice"
                        }
                      />
                      <Form.Check
                        inline
                        label="Multi Choice"
                        name="group1"
                        type="radio"
                        value="multi choice"
                        id="inline-radio-2"
                        style={{ marginLeft: "20px" }}
                        onChange={(e) =>
                          setQuestionDataToEdit({
                            ...questionDataToEdit,
                            typeOfAnswer: e.target.value,
                          })
                        }
                        checked={
                          questionDataToEdit?.typeOfAnswer == "multi choice"
                        }
                        disabled
                      />
                      <Form.Check
                        inline
                        label="Without Option"
                        name="group1"
                        type="radio"
                        value="without option"
                        id="inline-radio-3"
                        style={{ marginLeft: "20px" }}
                        onChange={(e) =>
                          setQuestionDataToEdit({
                            ...questionDataToEdit,
                            typeOfAnswer: e.target.value,
                          })
                        }
                        checked={
                          questionDataToEdit?.typeOfAnswer == "without option"
                        }
                        disabled
                      />
                    </div>
                  </Form>
                </Row>
              </div>
            </div>

            <div className="addQuizTimeDetails">
              <h6>Set Time</h6>
              <div className="quizTime">
                <div className="quizTimeDetails">
                  <h6>Minutes</h6>
                  {/* <input type="text" placeholder="00" /> */}
                  <select
                    // onChange={(e) =>
                    //   setQuestionDataToEdit({
                    //     ...questionDataToEdit,
                    //     timingMinute: e.target.value,
                    //   })
                    // }
                    id="minuteId"
                    onChange={(e) => setQuestionMinuteTimer(e)}
                  >
                    {minutes.map((minute, indx) => {
                      return (
                        <option key={minute?.value}>{minute.value}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="timeSeparator">
                  <span>:</span>
                </div>
                <div className="quizTimeDetails">
                  <h6>Seconds</h6>
                  {/* <input type="text" placeholder="00" /> */}
                  <select
                    // onChange={(e) =>
                    //   setQuestionDataToEdit({
                    //     ...questionDataToEdit,
                    //     timingSecond: e.target.value,
                    //   })
                    // }
                    id="secondId"
                    onChange={(e) => setQuestionSecondTimer(e)}
                    defaultValue={defaultSecond}
                  >
                    {seconds.map((second, indx) => {
                      return (
                        <option key={second?.value}>{second.value}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="addQuizForm">
              <h6>Question Title</h6>
              <input
                type="text"
                placeholder="Add Question Here"
                // onChange={(e) => handleQuestion(e)}
                value={questionDataToEdit?.question}
                onChange={(e) =>
                  setQuestionDataToEdit({
                    ...questionDataToEdit,
                    question: e.target.value,
                  })
                }
              />
            </div>

            <div className="addQuizForm">
              <h6>Add Answer</h6>
              <div className="answerAndTickAlign">
                <input
                  type="text"
                  value={isAnswerData}
                  placeholder="Add Correct Answer Here"
                  onChange={(e) => handleAnswers(e)}
                />
                <div className="alignImage">
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    onClick={clearInput}
                  >
                    <img
                      src={cross}
                      className="App-logo"
                      alt="close"
                      style={{
                        height: "11px",
                        width: "11px",
                        marginTop: "16px",
                      }}
                    />
                  </button>
                  <button
                    onClick={saveAnswers}
                    disabled={isAnswerData?.length == 0}
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    <img
                      style={{ backgroundColor: "#010101" }}
                      src={tick}
                      className="App-logo"
                      alt="tick"
                    />
                  </button>
                </div>
              </div>
            </div>

            {(questionDataToEdit?.typeOfQuestion == "photo" ||
              questionDataToEdit?.typeOfQuestion == "video") && (
              <div
                className="addQuizAttachment"
                style={{ marginBottom: "20px" }}
              >
                <span>
                  {questionDataToEdit?.typeOfQuestion == "photo"
                    ? "Attach Photo"
                    : "Attach Video"}
                </span>
                <div className="attachFileSection">
                  <span>
                    {(questionDataToEdit?.attachment || questionDataToEdit?.media) ? (
                      <h6 style={{ paddingTop: "9px", overflow:"hidden" }}>
                        {questionDataToEdit?.media ? questionDataToEdit?.media?.name : questionDataToEdit?.attachment}
                      </h6>
                    ) : (
                      <h6 style={{ paddingTop: "9px" }}>Browse File</h6>
                    )}
                  </span>
                  <form
                    action=""
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                  >
                    {questionDataToEdit?.typeOfQuestion == "photo" ? (
                      <input
                        type="file"
                        className="input-field"
                        multiple
                        name="file"
                        id="files"
                        hidden
                        accept="image/"
                        // onChange={handleFiles}
                        onChange={(e) =>
                          setQuestionDataToEdit({
                            ...questionDataToEdit,
                            media: e.target.files[0],
                          })
                        }
                      ></input>
                    ) : questionDataToEdit?.typeOfQuestion == "video" ? (
                      <input
                        type="file"
                        className="input-field"
                        multiple
                        name="file"
                        id="files"
                        hidden
                        accept=".mp4, .mkv, .mp3"
                        // onChange={handleFiles}
                        onChange={(e) =>
                          setQuestionDataToEdit({
                            ...questionDataToEdit,
                            media: e.target.files[0],
                          })
                        }
                      ></input>
                    ) : (
                      ""
                    )}
                    <img src={attachFile} />
                  </form>
                </div>
                {/* <img src={attachFile} className="App-logo" alt="attachFile" /> */}
              </div>
            )}

            {(
              <div style={{ marginBottom: "10px" }}>
                {questionDataToEdit?.typeOfQuestion == "photo" ? (
                  <img
                    src={
                      questionDataToEdit?.media
                        ? URL.createObjectURL(questionDataToEdit?.media)
                        : questionDataToEdit?.attachment
                    }
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : questionDataToEdit?.typeOfQuestion == "video" ? (
                  <video width="200" height="170" controls>
                    <source
                      src={
                        questionDataToEdit?.media ? 
                        URL.createObjectURL(questionDataToEdit?.media)
                        : questionDataToEdit?.attachment
                      }
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  ""
                )}
                {/* {(questionDataToEdit?.typeOfQuestion == "photo" || questionDataToEdit?.typeOfQuestion == "video") && <img
                  onClick={() => setQuestionDataToEdit({...questionDataToEdit, media: ""})}
                  src={deleteAttach}
                  style={{
                    height: "17px",
                    width: "17px",
                    position: "relative",
                    bottom: "40px",
                    left: "15px",
                    cursor: "pointer",
                  }}
                />} */}
              </div>
            )}

            {isAnswerList?.map((answer, indx) => {
              return (
                <div style={{ display: "flex" }}>
                  <div className="answerList">
                    <div className="answerData">
                      <div style={{ marginRight: "30px" }}>
                        <span>{indx + 1}</span>
                      </div>
                      <div>
                        <span>{answer?.answer}</span>
                      </div>
                    </div>
                    <div className="correctAnswer" id="corrAns">
                      <input
                        id={indx}
                        type="radio"
                        required
                        name="answer"
                        value={answer.correctAns}
                        onChange={(e) => markCorrectAnswer(e, answer.id)}
                        defaultChecked={answer.correctAns == true}
                      />
                      <h6>Correct Answer</h6>
                    </div>
                  </div>
                  <div className="deleteStyle">
                    <button onClick={() => deleteItem(answer.id)}>
                      <img
                        src={cross}
                        className="App-logo"
                        alt="close"
                        style={{ height: "11px", width: "11px" }}
                      />
                    </button>
                  </div>
                </div>
              );
            })}

            <div className="marksSection">
              <div className="correctAnsMarks">
                <h6>Correct Answer Number</h6>
                <input
                  type="number"
                  placeholder="Enter Correct Answer Number"
                  value={questionDataToEdit?.correctMarks}
                  onChange={(e) =>
                    setQuestionDataToEdit({
                      ...questionDataToEdit,
                      correctMarks: e.target.value,
                    })
                  }
                />
              </div>
              <div className="incorrectAnsMarks">
                <h6 style={{ color: "#E11717" }}>Incorrect Answer Number</h6>
                <input
                  type="number"
                  placeholder="Enter Incorrect Answer Number"
                  value={questionDataToEdit?.incorrectMarks}
                  onChange={(e) =>
                    setQuestionDataToEdit({
                      ...questionDataToEdit,
                      incorrectMarks: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="addQuestionFormButton">
              <button
                // type="submit"
                style={{ backgroundColor: "black" }}
                onClick={saveQuestionSet}
                disabled={
                  !(
                    (
                      questionDataToEdit?.typeOfQuestion &&
                      questionDataToEdit?.typeOfQuestion &&
                      questionDataToEdit?.media != "0" &&
                      questionDataToEdit?.typeOfAnswer &&
                      questionDataToEdit?.correctMarks &&
                      questionDataToEdit?.question &&
                      questionDataToEdit?.incorrectMarks
                    )
                    // &&
                    // (questionDataToEdit?.timingMinute && questionDataToEdit?.timingMinute)
                    // (questionDataToEdit?.timingSecond && questionDataToEdit?.timingSecond)
                    // correctAnsRadio
                  )
                }
              >
                <b>Save</b>
              </button>
              <button
                type="close"
                style={{ backgroundColor: "red" }}
                onClick={closeModalWithoutReload}
              >
                <b>Cancel</b>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddQuestions;
