import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Components/Login";
import Users from "./Components/Users";
import Quiz from "./Components/Quiz";
import Report from "./Components/Report";
import AcademyDashboard from "./Components/Academy/Dashboard";
import AcademyQuiz from "./Components/Academy/Quiz";
import QuizQuestionSet from "./Components/Academy/QuizQuestionSet";
import AdminDashboard from "./Components/Admin/Dashboard";
import Dashboard from "./Components/Academy/Dashboard";
import HomePage from "./Components/User/HomePage";
import Rules from "./Components/User/Quiz/Rules";
import UserDashboard from "./Components/User/Dashboard/Dashboard";
import UserQuizQuestion from "./Components/User/Quiz/UserQuizQuestion";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AcademyLogin from "./Components/Academy/AcademyLogin";
import Header from "./Components/Header";
import AggregatedReport from "./Components/AggregatedReport";
import UserQuiz from "./Components/User/Quiz/UserQuiz";

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/user/dashboard" element={<UserDashboard />} />
          <Route path="/user/rules/:id" element={<Rules />} />
          <Route path="/user/userquizquestion/:id" element={<UserQuiz />} />
          <Route path="/user/report/:id" element={<Report />} />
          <Route path="/academy/login" element={<AcademyLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/users" element={<Users />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/academy/quiz/report/:id" element={<Report />} />
          <Route path="/academy/quiz/report" element={<AggregatedReport />} />
          <Route path="/academy/dashboard" element={<AcademyDashboard />} />
          <Route path="/academy/quiz" element={<Dashboard />} />
          <Route
            path="/academy/quiz/questionSet/:id"
            element={<QuizQuestionSet />}
          />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
