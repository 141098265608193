// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brandLogo{
    display: flex
}

.brandLogo img{
    height: 54px;
    width: 54px;
}

.brandLogo h3{
    position: relative;
    top: 10px;
    left: 10px;
    color: #44475B;
    font-family: Poppins;
}

.me-auto Nav.Link{
    margin-left: 40px;
}

.academyQuizLink {
    color: #44475B;
    font-family: Poppins;
}

.profileImg{
    height: 51px;
    width: 51px;
    border-radius: 50%;
    float: right;
}

.profileVector{
    height: 21px;
    width: 21px;
    margin-top: 16px;
}

.me-auto{
    justify-content: space-between;
    padding: 0px 160px;
    position: relative;
    right: 129px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Header.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".brandLogo{\r\n    display: flex\r\n}\r\n\r\n.brandLogo img{\r\n    height: 54px;\r\n    width: 54px;\r\n}\r\n\r\n.brandLogo h3{\r\n    position: relative;\r\n    top: 10px;\r\n    left: 10px;\r\n    color: #44475B;\r\n    font-family: Poppins;\r\n}\r\n\r\n.me-auto Nav.Link{\r\n    margin-left: 40px;\r\n}\r\n\r\n.academyQuizLink {\r\n    color: #44475B;\r\n    font-family: Poppins;\r\n}\r\n\r\n.profileImg{\r\n    height: 51px;\r\n    width: 51px;\r\n    border-radius: 50%;\r\n    float: right;\r\n}\r\n\r\n.profileVector{\r\n    height: 21px;\r\n    width: 21px;\r\n    margin-top: 16px;\r\n}\r\n\r\n.me-auto{\r\n    justify-content: space-between;\r\n    padding: 0px 160px;\r\n    position: relative;\r\n    right: 129px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
