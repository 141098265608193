import React, { useState } from "react";
import { Container, Row, Col, Button, Modal, Tab, Tabs } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../Assets/Images/mQuiz-logo.png";
import Login from "./Login";
import UserSignup from "./UserSignup";
import "../../Styles/HomePage.css";

const HomeHeader = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="home-page-header">
      <Navbar className="navbar-bg" data-bs-theme="light">
        <Container fluid>
          <Row className="navbar-row" md={12} style={{ width: "100%" }}>
            <Col md={3}>
              <Navbar.Brand className="brand-name" href="#dashboard">
                <img src={Logo} height="50px" alt="logo" />
                <h2 className="product-title"> QuizSurveys</h2>
              </Navbar.Brand>
            </Col>
            <Col md={7}>
              <Nav>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                      <a className="nav-tab" href="#About">
                        About <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-tab" href="#Plans">
                        Plans
                      </a>
                      <a className="nav-tab" href="#GH">
                        Guides and Help
                      </a>
                      <a className="nav-tab" href="#Contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </Nav>
            </Col>
            <Col md={2}>
              <Button className="button" variant="light" onClick={handleShow}>
                Login/Register
              </Button>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <Modal
        className="homepage-modal"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w my-modal homepage-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body className="modal-body-home">
          <div className="modal-div-1">
            <p className="modal-body-text-1">
              Welcome <br /> to <b>QuizSurveys</b>
            </p>
            <p className="modal-body-text-2">
              Already have an account? <b>Sign in</b>
            </p>
            <div className="modal-img">
              <img src={Logo} height="251px" width="257px" alt="logo" />
            </div>
            <div class="signup-bottom">
            <p className="modal-body-text-3">Join Our Community</p>
            <p className="modal-body-text-4">
            Unlock Exclusive Benefits and Share <br />
            Your Opinions.
            </p>
            </div>
          </div>
          <div className="modal-div-2 ">
            <Tabs
              defaultActiveKey="login"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="signup" className="signup-tab" title="Sign up">
                <UserSignup
                  handleClose={handleClose}
                  handleShow={handleShow}
                  setShow={setShow}
                />
              </Tab>
              <Tab eventKey="login" className="login-tab" title="Login">
                <Login />
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomeHeader;
