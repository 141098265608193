import React, { useEffect, useRef, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardFooter from "../Dashboard/DashboardFooter";
import { Button, Container, Form, ProgressBar } from "react-bootstrap/esm";
import rm3 from "../../../Assets/Images/rm3.png";
import "../../../Styles/UserDashboard.css";
import { useParams } from "react-router-dom";
import { userQuizSaveUpdate } from "../../../apiClient";
import { toast } from "react-toastify";

const UserQuizQuestion = ({ setShowAnswer }) => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  let questionSet = JSON.parse(window.sessionStorage.getItem("questionSet"));
  let curIndex = parseInt(window.sessionStorage.getItem("curIndex"));

  let time;

  if (sessionStorage.getItem(`${curIndex}timer`)) {
    time = JSON.parse(window.sessionStorage.getItem(`${curIndex}timer`));
  } else {
    time = questionSet[curIndex].timing;
  }

  const [answer, setAnswer] = useState(null);
  const [isQuizComplete, setQuizComplete] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(curIndex);
  const [countTimer, setCountTimer] = useState(time);
  const [disableButton, setDisableButton] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    setCurrentIndex(curIndex);
  }, [curIndex]);

  useEffect(() => {
    if (questionSet.length - 1 === currentIndex) {
      setQuizComplete(true);
    }
  }, [currentIndex]);

  const Ref = useRef(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setCountTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  useEffect(() => {
    window.sessionStorage.setItem(
      `${curIndex}timer`,
      JSON.stringify(countTimer)
    );
    if (countTimer === "00:00") {
      const data = {
        quizId: id,
        questionId: questionSet[currentIndex]?.id,
        answers: [],
        quizCompleted: isQuizComplete,
        timing: countTimer,
        quizTiming: questionSet[currentIndex]?.timing,
      };
      handleQuizAnswer(data);
    }
  }, [countTimer]);

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    let time = JSON.parse(window.sessionStorage.getItem(`${curIndex}timer`));
    let array = time.split(":");
    let seconds = parseInt(array[0], 10) * 60 + parseInt(array[1], 10);
    deadline.setSeconds(deadline.getSeconds() + seconds);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const submitAnswer = (e) => {
    setDisableButton(true);
    const data = {
      quizId: id,
      questionId: questionSet[currentIndex].id,
      answers: [
        {
          id: answer,
          givenAnswer: true,
        },
      ],
      quizCompleted: isQuizComplete,
      timing: countTimer,
      quizTiming: questionSet[currentIndex].timing,
    };
    e.preventDefault();
    handleQuizAnswer(data);
  };

  const handleQuizAnswer = (data) => {
    userQuizSaveUpdate(data)
      .then((res) => {
        setDisableButton(false);
        window.sessionStorage.removeItem(`${curIndex}timer`);
        answerSubmit();
      })
      .catch((err) => {
        toast.error(err.message);
        setDisableButton(false);
      });
  };

  function answerSubmit() {
    window.sessionStorage.setItem("showAnswer", JSON.stringify(true));
    setShowAnswer(true);
  }

  let { question, answers, typeOfQuestion } = questionSet[currentIndex];

  return (
    <>
      <div className="user-qz-row-col-2">
        <ProgressBar
          className="user-quiz-progress-bar"
          striped
          variant="info"
          now={((currentIndex + 1) / questionSet?.length) * 100}
        />
        <div className="user-qz-row-col-2-div-1">
          <div className="user-qz-timer-div-1">
            <p className="user-qz-timer-text-1">
              Questions {currentIndex + 1} /{questionSet?.length}
            </p>
            {countTimer && <p className="user-qz-timer-text-2">{countTimer}</p>}
          </div>

          <div className="user-qz-timer-div-2">
            <p className="user-qz-timer-text-1">{currentIndex + 1} Question</p>
            <div className="user-qz-timer-q-container">
              {typeOfQuestion === "photo" && (
                <img
                  src={questionSet[currentIndex]?.attachment}
                  height="135px"
                />
              )}
              {typeOfQuestion === "video" && (
                <video
                  controls
                  src={questionSet[currentIndex]?.attachment}
                  height="240px"
                  width="500px"
                  type="audio/mp3"
                />
              )}
              <p className="user-qz-timer-text-3">{question}</p>
            </div>
          </div>

          <Form onSubmit={(e) => submitAnswer(e)}>
            {answers?.map((ans, i) => (
              <Form.Check
                key={i}
                className="mb-3 form-options"
                type="radio"
                name="radio"
                onChange={(e) => setAnswer(e.target.value)}
                required
                value={ans.id}
                id={ans.id}
                label={ans.answer}
              />
            ))}
            <div className="rules-buttons">
              <Button variant="dark" disabled>
                Back
              </Button>
              <Button disabled={disableButton} type="submit" variant="dark">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UserQuizQuestion;
