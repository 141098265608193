import React, { useEffect, useState } from "react";
import { Dropdown, Row, Col, Card, Button, Table } from "react-bootstrap";
import "../Styles/ReportStyle.css";
import Vector from "../Assets/Images/Vector.png";
import { useParams } from "react-router-dom";
import download from "../Assets/Images/download.png";
import { userReport } from "../apiClient";
import Header from "./Header";
import { toast } from "react-toastify";

function AggregatedReport() {
  const [startDate, setStartDate] = useState(new Date());
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    getAggregatedReport();
  }, []);

  const getAggregatedReport = () => {
    let dataToFetchReport = {
      quizId: "",
      selectedDate: "",
      quizName: "",
    };
    userReport(dataToFetchReport)
      .then((res) => setReportData(res.data))
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div style={{ padding: "20px", backgroundColor: "#F6F6F6" }}>
        <Row md={12}>
          <Col md={3}>
            <Dropdown className="quizDropdown">
              <Dropdown.Toggle>Select All Academy</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Option 1</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Option 3</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          {/* <Col md={3}>

                        <DatePicker wrapperClassName="launch-datepicker" className="reportDatePicker" selected={startDate} onChange={(date) => setStartDate(date)} />

                    </Col> */}
          <Col md={9}>
            <div className="searchContainer">
              <img src={Vector} className="App-logo" alt="Vector" />
              <input
                id="searchInput"
                type="text"
                placeholder="Search for Quiz name"
              />
            </div>
          </Col>
        </Row>
      </div>
      {reportData?.status?.status == "200" ? (
        <div className="reportTable">
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Academy</th>
                <th>Date</th>
                <th>Total User</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Test</td>
                <td>Test</td>
                <td>Test</td>
                <td>
                  <button className="downloadReportBtn">
                    <img
                      src={download}
                      style={{
                        height: "22px",
                        width: "22px",
                        marginRight: "25px",
                      }}
                    />
                    Download
                  </button>
                </td>
              </tr>
              <tr>
                <td>Test 2</td>
                <td>Test 2</td>
                <td>Test 2</td>
                <td>
                  <button className="downloadReportBtn">
                    <img
                      src={download}
                      style={{
                        height: "22px",
                        width: "22px",
                        marginRight: "25px",
                      }}
                    />
                    Download
                  </button>
                </td>
              </tr>
              <tr>
                <td>Test 3</td>
                <td>Test 3</td>
                <td>Test 3</td>
                <td>
                  <button className="downloadReportBtn">
                    <img
                      src={download}
                      style={{
                        height: "22px",
                        width: "22px",
                        marginRight: "25px",
                      }}
                    />
                    Download
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="dataNotFound">
          <h4>No Data Found!!</h4>
        </div>
      )}
    </>
  );
}

export default AggregatedReport;
