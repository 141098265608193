import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DeleteConfirmation = (props) => {
    const {onHide, deleteConfirmed} = props

    const sendConfirmation = () => {
        deleteConfirmed(true)
        onHide()
    }
  return (
    <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        size="sm"
        >
        <Modal.Header>
          <Modal.Title>Delete Question?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:"100px"}}>Do you want to delete this question?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Go Back
          </Button>
          <Button variant="primary" onClick={sendConfirmation}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default DeleteConfirmation;