import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getAcademyList } from "../../apiClient";
import kebab from "../../Assets/Images/kebab.png";
import { toast } from "react-toastify";

const AcademyTable = ({ isLoadTable }) => {
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    getAcademyData();
  }, [!isLoadTable]);

  const getAcademyData = () => {
    const dataToPost = {
      searchText: "",
    };

    getAcademyList(dataToPost)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <Table responsive="sm">
      <thead>
        <tr>
          <th>Academy Name</th>
          <th>Name</th>
          <th>Designation</th>
          <th>Email</th>
          <th>Phone</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData?.data?.length > 0 &&
          tableData?.data?.map((academy, indx) => {
            return (
              <tr>
                <td>{academy?.academyName}</td>
                <td>{academy?.name}</td>
                <td>{academy?.designation}</td>
                <td>{academy?.emailId}</td>
                <td>{academy?.mobileNumber}</td>
                <td>
                  <img src={kebab} />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default AcademyTable;
