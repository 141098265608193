import React from "react";
import mmlogo from "../../../Assets/Images/mmlogo.png";
import Logo from "../../../Assets/Images/mQuiz-logo.png";
import "../../../Styles/UserDashboard.css";

const DashboardFooter = () => {
  return (
    <div className="user-footer">
      <p style={{ marginLeft: "10px" }} className="footer-text-1">© Copyright 2023 MagmaQuiz.com. All Rights Reserved.</p>
      <div className="home-footer-div-2">
          <img width="50px" height="35px" src={mmlogo} alt="logo" />
          <p className="footer-text-3">
            <span className="footer-text-3-reguler">Powered by </span>
            <span className="footer-text-3-bold">Minemagma</span>
          </p>
        </div>
        </div>

  );
};

export default DashboardFooter;
