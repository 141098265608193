import React from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Logo from "../../../Assets/Images/mQuiz-logo.png";
import userImage from "../../../Assets/Images/avatar.jpg";
import { userLogout } from "../../../apiClient";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DashboardHeader = () => {
  const navigate = useNavigate();

  const logoutFunc = () => {
    userLogout()
      .then((res) => {
        if (res.status === 200) {
          window.localStorage.removeItem("UserToken");
          window.localStorage.removeItem("roleName");
          navigate("/");
        }
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      <Navbar className="navbar-bg dashboard-header" data-bs-theme="light">
        <Container fluid>
          <Row className="navbar-row" md={12} style={{ width: "100%" }}>
            <Col md={5}>
              <Navbar.Brand className="brand-name" href="#dashboard">
                <img src={Logo} height="50px" alt="logo" />
                <h2 className="product-title"> QuizSurveys</h2>
              </Navbar.Brand>
            </Col>
            <Col md={5}>
            <Nav>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                      <a className="nav-tab" href="#">
                        Dashboard <span className="sr-only">(current)</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Nav>
            </Col>
            <Col md={2} className="header-logout-div">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="profileDropD"
                  style={{ background: "transparent" }}
                >
                  <img src={userImage} className="profileImg" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => logoutFunc()}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

export default DashboardHeader;
