import React, { useState } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardFooter from "./DashboardFooter";
import { Row, Col } from "react-bootstrap";
import "../../../Styles/UserDashboard.css";
import { Button } from "react-bootstrap/esm";
import rm1 from "../../../Assets/Images/rm1.png";
import questNotAdded from "../../../Assets/Images/questNotAdded.png";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import { userQuizList, userQuizShareCode } from "../../../apiClient";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const UserDashboard = () => {
  const [searchText, setSearchText] = useState("");
  const [sharedCode, setSharedCode] = useState("");
  const [quizList, setQuizList] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  useEffect(() => {
    userQuizList({ searchText })
      .then((res) => {
        setQuizList(res?.data?.data);
      })
      .catch((err) => toast.error(err));
  }, [searchText]);

  const joinByAccessCode = (e) => {
    e.preventDefault();
    userQuizShareCode(sharedCode)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data) {
            navigate(`/user/rules/${res.data.data}`);
          } else {
            navigate("/user/dashboard");
            toast.error("Quiz already attended");
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        navigate("/user/dashboard");
      });
    handleClose();
  };

  return (
    <>
      <DashboardHeader />
      <div className="user-dashboard user-dashboard-bg ">
        <div className="dashboard-div-1">
          <p className="db-div-1-text-1 ">Filter By</p>
          <select name="cars" id="cars" className="db-div-1-select">
            <option value="all">All Quiz </option>
          </select>
          <input
            className="db-div-1-search"
            type="search"
            placeholder="Seach by quiz name"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <Button onClick={handleShow} className="db-div-1-button">
            Join Now
          </Button>
          <Modal show={show} className="access-code-modal">
            <Modal.Header className="access-code-modal-header">
              <Modal.Title className="access-code-modal-header-title">
                Join A Quiz
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="access-code-modal-body">
              <div className="access-modal-body-div-1">
                <label>Access Code</label>
                <br />
                <input
                  type="text"
                  id="sharedCode"
                  name="sharedCode"
                  value={sharedCode}
                  onChange={(e) => setSharedCode(e.target.value)}
                  placeholder="Enter Access Code or Link"
                  className="form-input-1"
                  required
                />
              </div>
              <div className="access-modal-body-div-2">
                <Button
                  type="submit"
                  onClick={(e) => joinByAccessCode(e)}
                  className="access-modal-button-1"
                  variant="secondary"
                >
                  Join Now
                </Button>
                <Button
                  className="access-modal-button-2"
                  variant="light"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div className="db-header-div-1">
          <Row className="db-header-row-1">
            {quizList?.length ? (
              quizList.map((quiz, index) => {
                return (
                  <Col
                    lg={3}
                    md={4}
                    className="db-header-row-col-1"
                    key={index}
                  >
                    <Card
                      onClick={() => {
                        if (quiz.quizAttended) {
                          navigate(`/user/report/${quiz.id}`);
                        } else {
                          navigate(`/user/rules/${quiz.id}`);
                        }
                      }}
                    >
                      {quiz.image ? (
                        <Card.Img
                          variant="top"
                          src={quiz.image}
                          alt={quiz.name}
                        />
                      ) : (
                        <Card.Img variant="top" src={rm1} alt={quiz.name} />
                      )}
                      <Card.Body>
                        <Card.Text>{quiz.name}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <div className="no-quiz-found">
                <img src={questNotAdded} height="150px" width="200px" />
                <h3>No Quiz Found</h3>
              </div>
            )}
          </Row>
          <div className="db-div-2">
            <Button
              className="db-div-2-button"
              type="submit"
              onClick={handleShow}
            >
              Join Now
            </Button>
          </div>
        </div>
      </div>
      <DashboardFooter />
    </>
  );
};

export default UserDashboard;
