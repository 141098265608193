import React, { useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import closeBtn from "../../Assets/Images/closeBtn.png";
import addQuizLogo from "../../Assets/Images/addQuizLogo.png";
import attachFile from "../../Assets/Images/attachFile.png";
import deleteAttach from "../../Assets/Images/deleteAttach.png";
import { addQuizAPI, quizInfo } from "../../apiClient";
import { useState } from "react";
import "../../Styles/academyQuiz.css";
import { toast } from "react-toastify";


function AddQuiz(props) {
  const [quizTitleData, setQuizTitleData] = useState("");
  const [quizType, setQuizType] = useState("");
  const [quizAttachment, setQuizAttachment] = useState("");
  const [isFileData, setIsFileData] = useState("");
  const [rulesAndReg, setRulesAndReg] = useState("");
  const [termsAndCond, setTermsAndCond] = useState("");
  const [quizDataToEdit, setQuizDataToEdit] = useState({
    id:"",
    name:"",
    photo: "",
    termConditions: "",
    ruleRegulations: ""

  })

  const {quizList} = props

  useEffect(() => {
    if(quizList){
      getQuizDetails(quizList)
    }
  }, [quizList])

  const closeModal = () => {
    props?.close();
  };

  const addNewQuiz = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("id", quizDataToEdit?.id);
    bodyFormData.append("type", quizDataToEdit?.type);
    bodyFormData.append("name", quizDataToEdit?.name);
    quizDataToEdit?.photo && bodyFormData.append("photo", quizDataToEdit?.photo);
    bodyFormData.append("termConditions", quizDataToEdit?.termConditions);
    bodyFormData.append("ruleRegulations", quizDataToEdit?.ruleRegulations);

    addQuizAPI(bodyFormData)
      .then((res) => {
        loadAndCloseModal();
      })
      .catch((err) => toast.error(err.message));
  };

  const handleQuizTitle = (e) => {
    setQuizTitleData(e.target.value);
  };

  const handleQuizType = (e) => {
    setQuizType(e.target.value);
  };

  const loadAndCloseModal = () => {
    closeModal();
    props?.getQuizList();
  };

  const handleFiles = (e) => {
    setIsFileData(e.target.files[0]);
  };

  const getTermsValue = (e) => {
    setTermsAndCond(e.target.value);
  };

  const getRulesValue = (e) => {
    setRulesAndReg(e.target.value);
  };

  console.log("quizDataToEdit?.photo - ", quizDataToEdit?.photo)

  const getQuizDetails = (id) => {
    quizInfo(id)
    .then((res) => {
      setQuizDataToEdit(res?.data?.data)
      setIsFileData(res?.data?.data?.image)
    })
    .catch((err) => toast.error(err.message));
  }
  


  return (
    <>
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="addQuizModal"
        scrollable={true}
      >
        <Modal.Body>
          <div className="closeBtn">
            <span className="closeButtonOnHeader">
              <img src={closeBtn} alt="closeBtn" onClick={closeModal} />
            </span>
          </div>
          <div className="container">
            <div className="quizHeader">
              <img src={addQuizLogo} className="App-logo" alt="addQuizLogo" />
              <span>Add Quiz</span>
            </div>

            <div className="addQuizForm">
              <h6>Quiz Title</h6>
              <input
                type="text"
                value={quizDataToEdit?.name}
                onChange={(e) => setQuizDataToEdit({...quizDataToEdit, name: e.target.value})}
                
              />
            </div>
            <div className="publicPrivate">
              <Row md={12}>
                <Form>
                  <div key="inline-radio" className="mb-3">
                    <Form.Check
                      inline
                      label="Private"
                      name="group1"
                      type="radio"
                      value= "PRIVATE"
                      id="inline-radio-1"
                      onChange={(e) => setQuizDataToEdit({...quizDataToEdit, type: e.target.value})}
                      checked={quizDataToEdit?.type == "PRIVATE"}
                    />
                    <Form.Check
                      inline
                      label="Public"
                      name="group1"
                      type="radio"
                      value= "PUBLIC"
                      id="inline-radio-2"
                      style={{ marginLeft: "20px" }}
                      onChange={(e) => setQuizDataToEdit({...quizDataToEdit, type: e.target.value})}
                      checked={quizDataToEdit?.type == "PUBLIC"}

                    />
                  </div>
                </Form>
              </Row>
            </div>
            <div className="addQuizAttachment">
              <span>Attached</span>
              <div className="attachFileSection">
                <span>
                  <h6>
                    {isFileData?.name ? (
                      <span style={{ position: "relative", top: "13px" }}>
                        {isFileData?.name}
                      </span>
                    ) : (
                      <span style={{ position: "relative", top: "13px" }}>
                        Attach File
                      </span>
                    )}
                  </h6>
                </span>
                <form
                  action=""
                  onClick={() => document.querySelector(".input-field").click()}
                >
                  <input
                    type="file"
                    className="input-field"
                    multiple
                    name="file"
                    id="files"
                    hidden
                    accept="image/*"
                    // onChange={handleFiles}
                    onChange={(e) => setQuizDataToEdit({...quizDataToEdit, photo: e.target.files[0]})}
                  ></input>
                  <img src={attachFile} />
                </form>
              </div>
            </div>
            {(quizDataToEdit.photo || quizDataToEdit.image) && (
              <div style={{ margin: "10px 0px" }}>
                <img
                  src={
                    quizDataToEdit.photo ? URL.createObjectURL(quizDataToEdit.photo) : quizDataToEdit.image
                  }
                  style={{ height: "100px", width: "100px" }}
                />
                <img
                  onClick={() => setIsFileData(null)}
                  src={deleteAttach}
                  style={{
                    height: "17px",
                    width: "17px",
                    position: "relative",
                    bottom: "40px",
                    left: "15px",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
            <div className="termsAndRules">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Terms and Conditions</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  // onChange={(e) => getTermsValue(e)}
                  value={quizDataToEdit?.termConditions}
                  onChange={(e) => setQuizDataToEdit({...quizDataToEdit, termConditions: e.target.value})}
                  // defaultValue={quizDataToEdit?.termConditions}
                />
              </Form.Group>
            </div>

            <div className="termsAndRules">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Rules and Regulations</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  // onChange={(e) => getRulesValue(e)}
                  value={quizDataToEdit?.ruleRegulations}
                  onChange={(e) => setQuizDataToEdit({...quizDataToEdit, ruleRegulations: e.target.value})}
                />
              </Form.Group>
            </div>

            <div className="addQuizFormButton">
              <button
                type="submit"
                style={{ backgroundColor: "black" }}
                onClick={addNewQuiz}
                disabled={
                  !(
                    (quizDataToEdit?.name) &&
                    (quizDataToEdit?.type) &&
                    (quizDataToEdit?.photo ? quizDataToEdit?.photo : quizDataToEdit?.image) &&
                    (quizDataToEdit?.termConditions) &&
                    (quizDataToEdit?.ruleRegulations)
                  )
                }
              >
                <b>Save</b>
              </button>
              <button
                type="close"
                style={{ backgroundColor: "red" }}
                onClick={closeModal}
              >
                <b>Cancel</b>
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default AddQuiz;
