import React, { useState } from "react";
import facebook from "../../Assets/Images/facebook.png";
import goggle from "../../Assets/Images/goggle.png";
import passwordHide from "../../Assets/Images/passwordHide.png";
import passwordView from "../../Assets/Images/passwordView.png";
import { Button } from "react-bootstrap/esm";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../Styles/HomePage.css";
import { baseurl } from "../../apiClient";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = useState({
    emailId: "",
    password: "",
  });

  const visiblePassword = (value) => {
    setShowPassword(value);
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${baseurl}/user/signIn`, user)
      .then((res) => {
        if (res.status === 200) {
          let user = localStorage.setItem(
            "UserToken",
            JSON.stringify(res.data.data.token)
          );
          localStorage.setItem(
            "roleName",
            JSON.stringify(res.data.data.roleName)
          );
          navigate("/user/dashboard");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      {/* <div className="login-buttons">
        <Button className="login-g-button">
          <img src={goggle} height="20px" alt="logo" />
          Continue With Google
        </Button>{" "}
        <br />
        <Button className="login-f-button">
          <img src={facebook} height="20px" alt="logo" />
          Continue With Facebook
        </Button>
      </div>
      <p className="sign-up-text-1">Or email</p> */}
      {/* <ToastContainer /> */}
      <form className="homepage-modal-form" onSubmit={(e) => handleSubmit(e)}>
        <label>Username/Email Address</label>
        <br />
        <input
          type="email"
          id="emailId"
          name="emailId"
          value={user.emailId}
          onChange={(e) => handleChange(e)}
          placeholder="Enter Email Address"
          className="form-input-1"
          required
        />
        <br />
        <label>Password</label>
        <br />
        <div className="password-visible-hide">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={user.password}
            onChange={(e) => handleChange(e)}
            placeholder="Enter Your Password"
            className="form-input-1"
            required
          />
          <span className="password-images">
            <img
              src={passwordView}
              height="18px"
              width="28px"
              onClick={() => visiblePassword(true)}
            />
            <img
              src={passwordHide}
              height="22px"
              width="28px"
              onClick={() => visiblePassword(false)}
            />
          </span>
        </div>
        <p className="forgot-password">Forgot Password?</p>
        <br />
        <Button type="submit" className="login-button">
          Login
        </Button>
      </form>
    </>
  );
};

export default Login;
