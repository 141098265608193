import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  ProgressBar,
  Spinner,
} from "react-bootstrap/esm";
import rm3 from "../../../Assets/Images/rm3.png";
import correctMark from "../../../Assets/Images/correctMark.png";
import wrongMark from "../../../Assets/Images/wrongMark.png";
import "../../../Styles/UserDashboard.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap/esm";
import { toast } from "react-toastify";
import { userQuizQuestionDetailById } from "../../../apiClient";

const UserQuizAnswer = ({ showAnswer, setShowAnswer }) => {
  const navigate = useNavigate();

  let { id } = useParams();

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  let questionSet = JSON.parse(window.sessionStorage.getItem("questionSet"));
  let curIndex = parseInt(window.sessionStorage.getItem("curIndex"));

  const [currentIndex, setCurrentIndex] = useState(curIndex);
  const [answerList, setAnswerList] = useState([]);
  const [show, setShow] = useState(false);
  const [loadingList, setLoadingList] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    userQuizQuestionDetailById(id, questionSet[curIndex].id)
      .then((res) => {
        setLoadingList(false);
        setAnswerList(res.data.data);
      })
      .catch((err) => toast.error(err.message));
  }, []);

  const handleNextQuestion = () => {
    setShowAnswer(false);
    window.sessionStorage.setItem("showAnswer", JSON.stringify(false));
    curIndex++;
    window.sessionStorage.setItem("curIndex", curIndex);
  };

  useEffect(() => {
    setCurrentIndex(curIndex);
  }, [curIndex]);

  const submitQuiz = () => {
    toast.success("Quiz is completed");
    navigate(`/user/report/${id}`);
    window.sessionStorage.removeItem(`${curIndex}timer`);
    window.sessionStorage.removeItem("questionSet");
    window.sessionStorage.removeItem("curIndex");
    window.sessionStorage.removeItem("showAnswer");
    window.sessionStorage.removeItem("MyData");
  };

  let { question, answers, typeOfQuestion } = questionSet[currentIndex];

  return !loadingList ? (
    <>
      <div className="user-qz-row-col-2">
        <ProgressBar
          className="user-quiz-progress-bar"
          striped
          variant="info"
          now={((currentIndex + 1) / questionSet?.length) * 100}
        />
        <div className="user-qz-row-col-2-div-1">
          <div className="user-qz-timer-div-1">
            <p className="user-qz-timer-text-1">
              Questions {currentIndex + 1} /{questionSet?.length}
            </p>
            {/* <p className="user-qz-timer-text-2">{countTimer}</p> */}
          </div>
          <div className="user-qz-timer-div-2">
            <p className="user-qz-timer-text-1">{currentIndex + 1}. Question</p>
            <div className="user-qz-timer-q-container">
              {typeOfQuestion === "photo" && (
                <img
                  src={questionSet[currentIndex]?.attachment}
                  height="135px"
                />
              )}
              {typeOfQuestion === "video" && (
                <video
                  controls
                  src={questionSet[currentIndex]?.attachment}
                  height="240px"
                  width="500px"
                  type="video/mp4"
                />
              )}
              <p className="user-qz-timer-text-3">{question}</p>
            </div>
          </div>
          <Form className="correct-options">
            {answers?.map((ans, id1) => {
              if (answerList[id1] === true) {
                return (
                  <div className="correct-option" key={id1}>
                    <div className="correct-option-div-1">
                      <img src={correctMark} height="24px" />
                      <p> {ans?.answer}</p>
                    </div>
                    <p className="correct-option-div-2">Correct</p>
                  </div>
                );
              } else {
                return (
                  <div className="wrong-option" key={id1}>
                    <div className="correct-option-div-1">
                      <img src={wrongMark} height="24px" />
                      <p> {ans?.answer}</p>
                    </div>
                  </div>
                );
              }
            })}
            <div className="rules-buttons">
              {questionSet.length - 1 === currentIndex ? (
                <Button
                  variant="dark"
                  disabled={!showAnswer}
                  onClick={handleShow}
                >
                  Submit Quiz
                </Button>
              ) : (
                <Button variant="dark" onClick={handleNextQuestion}>
                  Next Question
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>

      <Modal show={show} className="access-code-modal">
        <Modal.Header className="access-code-modal-header">
          <Modal.Title className="access-code-modal-title">
            Quiz Submission
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="access-code-modal-body">
          <p>Do you want to submit the quiz?</p>
          <div className="access-modal-body-div-2 ">
            <Button
              onClick={(e) => {
                submitQuiz();
              }}
              className="access-modal-button"
              variant="secondary"
            >
              Submit quiz
            </Button>
            <Button
              className="access-modal-button"
              variant="light"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  ) : (
    <div className="user-qz-row-col-2-spinner">
      <Spinner animation="border" size="sm" />
    </div>
  );
};

export default UserQuizAnswer;
