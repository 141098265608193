import React from "react";
import laptoplogo from "../../Assets/Images/laptoplogo.png";
import thinklogo from "../../Assets/Images/thinklogo.png";
import answerslogo from "../../Assets/Images/answerslogo.png";
import questionlogo from "../../Assets/Images/questionlogo.png";
import sharelogo from "../../Assets/Images/sharelogo.png";
import quizimage from "../../Assets/Images/quizimage.png";
import image157 from "../../Assets/Images/image157.png";
import image158 from "../../Assets/Images/image158.png";
import image159 from "../../Assets/Images/image159.png";
import image627 from "../../Assets/Images/image627.png";
import image628 from "../../Assets/Images/image628.png";
import image629 from "../../Assets/Images/image629.png";
import Rectangle550 from "../../Assets/Images/Rectangle550.png";
import Rectangle551 from "../../Assets/Images/Rectangle551.png";
import Rectangle552 from "../../Assets/Images/Rectangle552.png";
import Rectangle553 from "../../Assets/Images/Rectangle553.png";
import Rectangle554 from "../../Assets/Images/Rectangle554.png";
import Rectangle556 from "../../Assets/Images/Rectangle556.png";
import developerlaptop from "../../Assets/Images/developerlaptop.png";
import doubleqoutes from "../../Assets/Images/doubleqoutes.png";
import { Button, Col, Container, Row, Card } from "react-bootstrap";
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";
import "../../Styles/HomePage.css";

const HomePage = () => {
  return (
    <div className="dashboard">
      <HomeHeader />
      <Container fluid className="section-1">
        <div className="laptoptext">
          <p className="laptoptext-1">
            Create & Share <br /> Fun Quizzes <br /> Effortlessly
          </p>
          <p className="laptoptext-2">
            Create engaging and interactive quizzes without a hassle
          </p>
          <Button className="dashboard-button">Create Quiz</Button>
        </div>

        <img src={laptoplogo} className="laptoplogo" alt="laptop" />
      </Container>
      <p className="sc2-text-1">Ready to Build Your Custom Quiz ?</p>
      <Container className="section-2">
        <Row className="sc2-row">
          <Col className="sc2-row-col" md={5}>
            <Col md={3}>
              <img height="80px" width="80px" src={thinklogo} alt="logo" />
            </Col>
            <Col md={9}>
            <div>
              <p className="sc2-text-2">Create</p>
              <p className="sc2-text-3">
                Choose from our templates or build one from scratch, MagmaQuiz
                helps you create a quiz that fits your every need.
              </p>
            </div>
            </Col>
          </Col>
          <Col className="sc2-row-col" md={5}>
          <Col md={3}>
            <img height="80px" width="80px" src={questionlogo} alt="logo" />
            </Col>
            <Col md={9}>
            <div>
              <p className="sc2-text-2">Customise</p>
              <p className="sc2-text-3">
                Design fully customisable quizzes that stand out, are fun and
                are interactive to keep your audiences engaged.
              </p>
            </div>
            </Col>
          </Col>
          <Col className="sc2-row-col" md={5}>
          <Col md={3}>
            <img height="80px" width="80px" src={answerslogo} alt="logo" />
            </Col>
            <Col md={9}>
            <div>
              <p className="sc2-text-2">Edit</p>
              <p className="sc2-text-3">
                Modify your existing effortlessly with the help of MagmaQuiz.
                You can add or remove questions with just one click.
              </p>
            </div>
            </Col>
          </Col>
          <Col className="sc2-row-col" md={5}>
          <Col md={3}>
            <img height="80px" width="80px" src={sharelogo} alt="logo" />
            </Col>
            <Col md={9}>
            <div>
              <p className="sc2-text-2">Share</p>
              <p className="sc2-text-3">
                Share your custom online quizzes for seamless interactions and
                reach a larger audience.
              </p>
            </div>
            </Col>
          </Col>
        </Row>
        <div style={{ textAlign: "center" }}>
          <Button className="dashboard-button">Get Started</Button>
        </div>
      </Container>
      <Container fluid className="section-3">
        <Row md={10} className="sc3-row">
          <Col lg={{ span: 5, offset: 1 }} xs={4}>
            <img src={quizimage} height="600px" width="400px" />
          </Col>
          <Col md={{ span: 5 }}>
            <p className="sc3-text-1">
              A Quiz Builder That <br /> Enhances Every User's <br /> Learning
              Experience
            </p>
            <p className="sc3-text-2">
              There are several quiz builders today, but here's why you should
              choose MagmaQuiz.
            </p>
            <Row className="sc3-row-2">
              <Col md={12} className="sc3-row-2-col">
                <img height="80px" src={image157} alt="logo" />
                <div style={{marginLeft:"10px"}}>
                  <p className="sc3-row-2-text-1">
                    At MagmaQuiz, we don't just provide an
                  </p>
                  <p className="sc3-row-2-text-2">
                    easy-to-use platform to create engaging and interactive
                    quizzes. We help our users accelerate growth by assisting
                    them in designing personalised and interactive content that
                    ensures high outcomes.
                  </p>
                </div>
              </Col>
              <Col md={12} className="sc3-row-2-col">
                <img height="80px" src={image158} alt="logo" />
                <div style={{marginLeft:"10px"}}>
                  <p className="sc3-row-2-text-1">
                    Our professional designs and layouts
                  </p>
                  <p className="sc3-row-2-text-2">
                    are completely customisable. Whether you want to create a
                    multi-choice quiz or one that includes audio and visuals,
                    we've got you covered. Our features and elements can be user
                    as per your requirement.
                  </p>
                </div>
              </Col>
              <Col md={12} className="sc3-row-2-col">
                <img height="80px" src={image159} alt="logo" />
                <div style={{marginLeft:"10px"}}>
                  <p className="sc3-row-2-text-1">
                    We help our users create quizzes
                  </p>
                  <p className="sc3-row-2-text-2">
                    that are responsive and compatible with all types of
                    devices. Our goal is to help our users reach a wider
                    audience by making our quizzes user-friendly and easily
                    accessible regardless of their device.
                  </p>
                </div>
              </Col>
              <Col>
                <Button className="dashboard-button">Get Started</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="section-4">
        <p className="sc2-text-1">Quizzes You Can Customise</p>
        <Row className="sc4-row-1">
          <Col lg={4} md={6} className="sc4-row-1-col">
            <Card>
              <Card.Img variant="top" src={Rectangle550} />
              <Card.Body>
                <Card.Title className="card-title">Industry Specific Quizzes</Card.Title>
                <Card.Text className="card-text">
                  Create quizzes specific to your industry and grow your
                  business.
                </Card.Text>
                <Card.Link className="card-link" variant="primary" href="">Learn More</Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} className="sc4-row-1-col">
            <Card>
              <Card.Img variant="top" src={Rectangle551} />
              <Card.Body>
                <Card.Title>Trivia Quizzes</Card.Title>
                <Card.Text>
                  Attract new audiences and improve your reach with fun trivia
                  quizzes.
                </Card.Text>
                <Card.Link variant="primary">Learn More</Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} className="sc4-row-1-col">
            <Card>
              <Card.Img variant="top" src={Rectangle552} />
              <Card.Body>
                <Card.Title>Lead Generation Quizzes</Card.Title>
                <Card.Text>
                  Skyrocket lead generation with simple yet effective quiz
                  templates.
                </Card.Text>
                <Card.Link variant="primary">Learn More</Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} className="sc4-row-1-col">
            <Card>
              <Card.Img variant="top" src={Rectangle553} />
              <Card.Body>
                <Card.Title>Professional Online Surveys</Card.Title>
                <Card.Text>
                Online surveys are easy to create, disseminate, and gather 
                response.
                </Card.Text>
                <Card.Link variant="primary">Learn More</Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} className="sc4-row-1-col">
            <Card>
              <Card.Img variant="top" src={Rectangle554} />
              <Card.Body>
                <Card.Title>Personalised Quizzes</Card.Title>
                <Card.Text>
                  Tailor-make fun quizzes that suit your audience's needs
                  without a hassle.
                </Card.Text>
                <Card.Link variant="primary">Learn More</Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} className="sc4-row-1-col">
            <Card>
              <Card.Img variant="top" src={Rectangle556} />
              <Card.Body>
                <Card.Title>Social Quizzes</Card.Title>
                <Card.Text>
                  Keep your audience entertained and engaged with interesting
                  quizzes.
                </Card.Text>
                <Card.Link variant="primary">Learn More</Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 3, offset: 5 }}>
            <Button className="dashboard-button">Browse All Quiz</Button>
          </Col>
        </Row>
      </Container>
      <Container className="section-5">
        <p className="sc2-text-1">Testimonials</p>
        <Row>
          <Col md={6}>
            <img src={developerlaptop} height="350px" alt="logo" />
          </Col>
          <Col md={6} className="sc5-row-col-2">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={doubleqoutes} height="60px" alt="logo" />
            </div>
            <p className="sc5-text-1" >
              I hate customer satisfaction.
              <br /> Satisfaction sets a low bar. People <br /> should be
              DELIGHTED when they <br /> use MagmaQuiz.
            </p>
            <p className="sc5-text-2">Sameer Saini</p>
            <p className="sc5-text-3">Founder & CEO</p>
          </Col>
        </Row>
      </Container>
      <Container className="section-6">
        <p className="sc2-text-1">
          Let's Grow Together !
        </p>
        <Row className="sc6-row-1">
          <Col md={4} className="sc6-flex">
            <img src={image627} height="80px" alt="logo" />
            <div style={{marginLeft:"10px"}}>
              <p className="sc6-text-1">
                Create Unlimited <br /> Quizzes
              </p>
              <p className="sc6-text-2">
                Choose from a plethora of templates and build custom quizzes for
                all types of audiences.{" "}
              </p>
            </div>
          </Col>
          <Col md={4} className="sc6-flex">
            <img src={image628} height="80px" alt="logo" />
            <div style={{marginLeft:"10px"}}>
              <p className="sc6-text-1">
                Trusted By <br /> Many
              </p>
              <p className="sc6-text-2">
                MagmaQuiz is the preferred quiz builder for users from all
                industries worldwide.{" "}
              </p>
            </div>
          </Col>
          <Col md={4} className="sc6-flex">
            <img src={image629} height="80px" alt="logo" />
            <div style={{marginLeft:"10px"}}>
              <p className="sc6-text-1">
                Growth <br /> Guaranteed!
              </p>
              <p className="sc6-text-2">
                You just don't get a cost-effective quiz builder with MagmaQuiz.
                You get one that guarantees success.{" "}
              </p>
            </div>
          </Col>
        </Row>
        <div className="sc6-buttons">
          <Button className="sc6-button-1">Sign Up</Button>
          <Button className="sc6-button-2">Compare Plans</Button>
        </div>
      </Container>
      {/* <section className="section-2"></section> */}
      <HomeFooter />
    </div>
  );
};

export default HomePage;