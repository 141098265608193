import React, { useState } from "react";
import "../../Styles/login.css";
import { Row, Col, Button } from "react-bootstrap";
import Logo from "../../Assets/Images/mQuiz-logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { baseurl } from "../../apiClient";

function AcademyLogin() {
  const [user, setUser] = useState({
    emailId: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${baseurl}/academy/signIn`, user)
      .then((res) => {
        if (res.status === 200) {
          let user = localStorage.setItem(
            "UserData",
            JSON.stringify(res.data.data.token)
          );
          if (res?.data?.data?.roleName === "Academy") {
            navigate("/academy/dashboard");
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="academyLoginComponent">
      <Row md={12}>
        <Col md={6}></Col>
        <Col md={6}>
          <Row>
            <Col>
              <div className="academyLoginSection">
                <div className="academyLoginBox">
                  <div className="academyLoginHeader">
                    <p className="academyWelcomText">Welcome to <b>QuizSurveys</b></p>
                    <div className="academySigninImg">
                      <img src={Logo} />
                    </div>
                    <p className="academySignintext">
                      Sign in to <b>Academy</b>
                    </p>
                  </div>

                  <div style={{ marginTop: "20px" }} className="academyLoginForm">
                    <form className="academyform" onSubmit={(e) => handleSubmit(e)}>
                      <label>Email Address</label>
                      <br />
                      <input
                        type="email"
                        id="emailId"
                        name="emailId"
                        value={user.emailId}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter Your Email Address"
                        className="form-input-1"
                        required
                      />
                      <br />
                      <label style={{ marginTop: "20px" }}>Password</label>
                      <br />
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={user.password}
                        onChange={(e) => handleChange(e)}
                        placeholder="Enter Your Password"
                        className="form-input-1"
                        required
                      />
                      <br />
                      <div style={{ marginTop: "20px" }} className="academy-login-button">
                        <Button type="submit" className="login-button">
                          Login
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default AcademyLogin;
