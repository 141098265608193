import React, { useEffect, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardFooter from "../Dashboard/DashboardFooter";
import { Button, Container } from "react-bootstrap/esm";
import rm3 from "../../../Assets/Images/rm3.png";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import "../../../Styles/UserDashboard.css";
import {
  startUserQuiz,
  termsByQuizId,
  userQuizSaveUpdate,
} from "../../../apiClient";
import { toast } from "react-toastify";

const Rules = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [terms, setTerms] = useState("");

  useEffect(() => {
    termsByQuizId(id)
      .then((res) => {
        setTerms(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        navigate("/user/dashboard");
      });
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const startQuizFunction = () => {
    handleClose();
    startUserQuiz(id)
      .then((res) => {
        if (res.status === 200) {
          if (!res?.data?.data?.quizQuestionResponses) {
            toast.error("quiz does not have any questions");
            navigate("/user/dashboard");
          } else {
            window.sessionStorage.setItem(
              "MyData",
              JSON.stringify(res.data.data)
            );
            window.sessionStorage.setItem(
              "questionSet",
              JSON.stringify(res.data.data.quizQuestionResponses)
            );
            window.sessionStorage.setItem("curIndex", 0);
            window.sessionStorage.setItem("showAnswer", JSON.stringify(false));
            let questionSet = JSON.parse(
              window.sessionStorage.getItem("questionSet")
            );
            let myData = JSON.parse(window.sessionStorage.getItem("MyData"));
            const data = {
              quizId: id,
            };
            userQuizSaveUpdate(data);
            navigate(`/user/userquizquestion/${id}`);
          }
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <DashboardHeader />
      <Container fluid className="user-dashboard-bg">
        <div className="rules-row-1">
          <div className="rules-row-col-1">
            {terms?.photo ? (
              <img src={terms?.photo} height="135px" alt="logo" />
            ) : (
              <img src={rm3} height="135px" alt="logo" />
            )}
            <p className="rl-rw-cl-text-1">{terms?.quizName}</p>
            <p className="user-qz-timer-text-2">{terms?.quizTiming}</p>
          </div>
          <div className="rules-row-col-2">
            <p className="rl-cl-text-1">
              Hello! Your quiz is ready, please read carefully our
              <span className="rl-cl-text-1-span">
                “Rules & regulation”
              </span>{" "}
              and <span className="rl-cl-text-1-span">“Term & Conditions”</span>
            </p>
            <p className="rl-cl-text-2">Rules & regulations</p>
            {/* <ol type="1">
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
              <li>elit. Duis eget maximus purus.</li>
              <li>Suspendisse cursus justo tellus,Suspendisse cursus justo</li>
              <li>tellus, vitae ultricies odio iaculis at. Donec</li>
            </ol> */}
            <p className="rl-cl-text-3">{terms?.ruleRegulation}</p>
            <p className="rl-cl-text-2">Terms & conditions</p>
            <p className="rl-cl-text-3">{terms?.termCondition}</p>
            <div className="rules-buttons">
              <Button
                variant="dark"
                onClick={() => {
                  navigate(`/user/dashboard`);
                }}
              >
                Back
              </Button>
              <Button variant="dark" onClick={handleShow}>
                Start Now
              </Button>
            </div>
          </div>
        </div>
        <Modal show={show} className="access-code-modal">
          <Modal.Header className="access-code-modal-header">
            <Modal.Title className="access-code-modal-title">
              Please agree to join our quiz
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="access-code-modal-body">
            <div className="access-modal-body-div-1">
              <p>{terms?.termCondition}</p>
            </div>
            <div className="access-modal-body-div-2 ">
              <Button
                onClick={(e) => {
                  startQuizFunction();
                }}
                className="access-modal-button-1"
                variant="secondary"
              >
                Agree
              </Button>
              <Button
                className="access-modal-button-2"
                variant="light"
                onClick={handleClose}
              >
                I don't agree
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
      <DashboardFooter />
    </>
  );
};

export default Rules;
