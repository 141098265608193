import axios from "axios";

// let baseurl = "https://dev.apis.quizsurveys.com";
let baseurl = "https://apis.quizsurveys.com";

const apiUserLogoutInstance = axios.create({
  baseURL: baseurl,
});

const apiAcademyLogoutInstance = axios.create({
  baseURL: baseurl,
});

const apiQuizInstance = axios.create({
  baseURL: `${baseurl}/quiz`,
});

const apiQuestionInstance = axios.create({
  baseURL: `${baseurl}/quiz-questions`,
});

const apiAcademyInstance = axios.create({
  baseURL: `${baseurl}/academy`,
});

const apiUserInstance = axios.create({
  baseURL: `${baseurl}/user`,
});

const apiQuizListInstance = axios.create({
  baseURL: `${baseurl}/quiz`,
});

const apiUserQuizInstance = axios.create({
  baseURL: `${baseurl}/user-quiz`,
});

const apiTermsConditionInstance = axios.create({
  baseURL: `${baseurl}/term-condition`,
});

async function handleAPI(recdConfig) {
  const config = recdConfig;
  const token = JSON.parse(localStorage.getItem("UserData"));
  const tokenId = token;
  try {
    config.headers["Authorization"] = `Bearer ${tokenId}`;
    return config;
  } catch (err) {
    return config;
  }
}

async function handleUserAPI(recdConfig) {
  const config = recdConfig;
  const token = JSON.parse(localStorage.getItem("UserToken"));
  const tokenId = token;
  try {
    config.headers["Authorization"] = `Bearer ${tokenId}`;
    return config;
  } catch (err) {
    return config;
  }
}

apiQuizInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

apiQuestionInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

apiAcademyInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

apiUserInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleUserAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

apiQuizListInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleUserAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

apiTermsConditionInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleUserAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

apiUserQuizInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleUserAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

apiUserLogoutInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleUserAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

apiAcademyLogoutInstance.interceptors.request.use(
  async (recdConfig) => {
    return handleAPI(recdConfig);
  },
  (error) => Promise.reject(error)
);

const addQuizAPI = (data) => {
  return apiQuizInstance.post(`/saveOrUpdate`, data);
};

const quizList = (postData) => {
  return apiQuizInstance.post(`/list`, postData);
};

const publishQuizData = (id, status) => {
  return apiQuizInstance.put(`/changePublishStatus/${id}/${status}`);
};

const saveQuestions = (data) => {
  return apiQuestionInstance.post(`/saveOrUpdate`, data);
};

const questionsList = (data) => {
  return apiQuestionInstance.post(`/list`, data);
};

const addQuestionExcel = (data) => {
  return apiQuestionInstance.post(`/importQuizQuestions`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getQuestionsData = (id) => {
  return apiQuestionInstance.get(`/get/${id}`);
};

const deleteQuestionsData = (id) => {
  return apiQuestionInstance.delete(`/delete/${id}`);
};

const addAcademy = (data) => {
  return apiAcademyInstance.post(`/saveOrUpdate`, data);
};

const quizInfo = (id) => {
  return apiQuizInstance.get(`/get/${id}`);
};

const getAcademyList = (data) => {
  return apiAcademyInstance.post(`/list`, data);
};

const userQuizList = (data) => {
  return apiQuizListInstance.post(`/list`, data);
};

const termsByQuizId = (id) => {
  return apiTermsConditionInstance.get(`/termConditions/${id}`);
};

const startUserQuiz = (id) => {
  return apiUserQuizInstance.post(`/getQuizDetailById/${id}`, id);
};

const userQuizSaveUpdate = (data) => {
  return apiUserQuizInstance.post(`/saveOrUpdate`, data);
};

const userQuizShareCode = (sharedCode) => {
  return apiUserQuizInstance.post(`searchBySharedCode/${sharedCode}`, {
    sharedCode,
  });
};

const userQuizQuestionDetailById = (id, questionId) => {
  return apiUserQuizInstance.post(`/getQuizDetailById/${id}/${questionId}`, {
    quizId: id,
    quizQuestionId: questionId,
  });
};

const userReport = (postData) => {
  return apiUserQuizInstance.post(`/getReport`, postData);
};

const userLogout = () => {
  return apiUserLogoutInstance.get("/signOut");
};

const academyLogout = () => {
  return apiAcademyLogoutInstance.get("/signOut");
};

export {
  addQuizAPI,
  quizList,
  saveQuestions,
  addAcademy,
  questionsList,
  quizInfo,
  getAcademyList,
  userQuizList,
  termsByQuizId,
  startUserQuiz,
  userQuizSaveUpdate,
  userQuizQuestionDetailById,
  userQuizShareCode,
  userReport,
  userLogout,
  academyLogout,
  addQuestionExcel,
  baseurl,
  getQuestionsData,
  publishQuizData,
  deleteQuestionsData,
};
