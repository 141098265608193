import React, { useEffect, useState } from "react";
import { Dropdown, Row, Col, Card, Button, Container } from "react-bootstrap";
import "../Styles/ReportStyle.css";
import rm3 from "../Assets/Images/rm3.png";
import Vector from "../Assets/Images/Vector.png";
import { useLocation, useParams } from "react-router-dom";
import noOfQuest from "../Assets/Images/noOfQuest.png";
import correctAns from "../Assets/Images/correctAns.png";
import wrongAns from "../Assets/Images/wrongAns.png";
import questionHand from "../Assets/Images/questionHand.png";
import questionSad from "../Assets/Images/questionSad.png";
import totalMarksImg from "../Assets/Images/totalMarks.png";
import Header from "./Header";
import { userReport } from "../apiClient";
import { toast } from "react-toastify";

function Report() {
  const { id } = useParams();

  const location = useLocation();

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  const [userReportDetails, setUserReport] = useState();

  useEffect(() => {
    userReport({
      quizId: id,
      selectedDate: "",
      quizName: "",
    })
      .then((res) => {
        setUserReport(res?.data?.data);
      })
      .catch((err) => toast.error(err.message));
  }, []);

  const downloadPdf = () => {
    window.print();
    // const input = document.getElementById("reportDetails");
    // html2canvas(input, {
    //   logging: true,
    //   letterRendering: 1,
    //   useCORS: true,
    // }).then((canvas) => {
    //   const imgWidth = 200;
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   const imgData = canvas.toDataURL("img/png");
    //   const pdf = new jsPDF("p", "mm", "a4");
    //   pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    //   pdf.save("quizrank.pdf");
    // });
  };

  return (
    <>
      <div>
        <Header />
      </div>
      {userReportDetails ? (
        <>
          <div style={{ padding: "20px", backgroundColor: "#F6F6F6" }}>
            <Row md={12}>
              <Col md={4}>
                <Dropdown className="quizDropdown">
                  <Dropdown.Toggle>All Quiz</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Option 1</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Option 3</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={6}>
                <div className="searchContainer">
                  <img src={Vector} className="App-logo" alt="Vector" />
                  <input
                    id="searchInput"
                    type="text"
                    placeholder="Search for Quiz name"
                  />
                </div>
              </Col>
              <Col md={2}>
                <div className="searchButton">
                  <button type="button" onClick={() => downloadPdf()}>
                    <b>Download Report</b>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <div id="reportDetails" className="reportDetails">
            <div className="reportsCardMain">
              <Card className="noOfQuest">
                <Card.Body>
                  <Row md={12}>
                    <Col md={3}>
                      <Card.Img
                        variant="top"
                        src={noOfQuest}
                        style={{
                          minHeight: "50%",
                          minWidth: "100%",
                          borderRadius: "50%",
                          position: "relative",
                          top: "27px",
                        }}
                      />
                    </Col>
                    <Col md={9}>
                      <div className="reportCardInnerDetails">
                        No of question
                      </div>
                      <div className="reportInnerDetails">
                        {userReportDetails?.userReport?.noOfQuestion}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="correctAns">
                <Card.Body>
                  <Row md={12}>
                    <Col md={3}>
                      <Card.Img
                        variant="top"
                        src={correctAns}
                        style={{
                          minHeight: "50%",
                          minWidth: "100%",
                          borderRadius: "50%",
                          position: "relative",
                          top: "27px",
                        }}
                      />
                    </Col>
                    <Col md={9}>
                      <div className="reportCardInnerDetails">
                        Questions Attended
                      </div>
                      <div className="reportInnerDetails">
                        {
                          userReportDetails?.userReport
                            ?.totalNoOfQuestionAttended
                        }
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="incorrectAns">
                <Card.Body>
                  <Row md={12}>
                    <Col md={3}>
                      <Card.Img
                        variant="top"
                        src={wrongAns}
                        style={{
                          minHeight: "50%",
                          minWidth: "100%",
                          borderRadius: "50%",
                          position: "relative",
                          top: "27px",
                        }}
                      />
                    </Col>
                    <Col md={9}>
                      <div className="reportCardInnerDetails">
                        Correct Answer
                      </div>
                      <div className="reportInnerDetails">
                        {userReportDetails?.userReport?.noOfCorrectAnswer}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
            <Container fluid>
              <Row>
                <Col md={4} className="report-div-2-col">
                  <img src={questionHand} height="150px" alt="logo" />
                  <div>
                    <div className="report-div-2-col-text-1">
                      No of Positive Marks
                    </div>
                    <div className="report-div-2-col-text-2">
                      {userReportDetails?.userReport?.positiveMarks}
                    </div>
                  </div>
                </Col>
                <Col md={4} className="report-div-2-col">
                  <img src={questionSad} height="150px" alt="logo" />
                  <div>
                    <div className="report-div-2-col-text-1">
                      No of Negative Marks
                    </div>
                    <div className="report-div-2-col-text-2">
                      {userReportDetails.userReport.negativeMarks}
                    </div>
                  </div>
                </Col>
                <Col md={4} className="report-div-2-col">
                  <img src={totalMarksImg} height="150px" alt="logo" />
                  <div>
                    <div className="report-div-2-col-text-1">Total Marks</div>
                    <div className="report-div-2-col-text-2">
                      {userReportDetails.userReport.marks}
                    </div>
                  </div>
                </Col>
              </Row>
              <fieldset className="report-fieldset">
                <legend className="report-legend">
                  {userReportDetails?.quizName}
                </legend>
                {userReportDetails.photo ? (
                  <img
                    src={userReportDetails.photo}
                    height="150px"
                    alt="logo"
                  />
                ) : (
                  <img src={rm3} height="150px" alt="logo" />
                )}
                <p className="report-fieldset-text">
                  {userReportDetails?.details}
                </p>
              </fieldset>
            </Container>
          </div>
        </>
      ) : null}
    </>
  );
}

export default Report;
