import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../Assets/Images/mQuiz-logo.png";
import mmlogo from "../../Assets/Images/mmlogo.png";
import mediaicons from "../../Assets/Images/mediaicons.png";
import mQuizlogo from "../../Assets/Images/mQuiz-logo.png";
import "../../Styles/HomePage.css";
import { useNavigate } from "react-router-dom";

const HomeFooter = () => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-footer">
      <img src={mQuizlogo} className="footer-image" alt="logo" />
      <Navbar
        className="navbar-bg"
        data-bs-theme="light"
        style={{ width: "100%" }}
      >
        <Container fluid>
          <Row className="navbar-row" md={12} style={{ width: "100%" }}>
            <Col md={3}>
              <Navbar.Brand className="brand-name" href="#dashboard">
                <img src={Logo} height="50px" alt="logo" />
                <h2 className="product-title"> QuizSurveys</h2>
              </Navbar.Brand>
            </Col>
            <Col md={6}>
              <Nav>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                      <a className="nav-tab" href="#About">
                        About <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-tab" href="#Plans">
                        Plans
                      </a>
                      <a className="nav-tab" href="#GH">
                        Guides and Help
                      </a>
                      <a className="nav-tab" href="#Contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </Nav>
            </Col>
            <Col md={3}>
              <Button
                className="button"
                variant="light"
                onClick={() => {
                  navigate("/academy/login");
                }}
              >
                Login in as Company/Institute
              </Button>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <div className="footer-2">
        <div className="home-footer-div-1">
          <p className="footer-text-1">
            © Copyright 2023 minemagma.com. All Rights Reserved.
          </p>
          <p className="footer-text-2">Terms of Use | Privacy Policy</p>
          <img src={mediaicons} width="148px" height="19px" alt="logo" />
        </div>
        <div className="home-footer-div-2">
          <img width="50px" height="35px" src={mmlogo} alt="logo" />
          <p className="footer-text-3">
            <span className="footer-text-3-reguler">Powered by </span>
            <span className="footer-text-3-bold">Minemagma</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
