import React, { useState } from "react";
import Header from "../Header";
import AcademyQuiz from "./Quiz";
import Report from "../Report";

function AcademyDashboard() {
  const [isShowReport, setIsShowReport] = useState(false);

  const displayReport = (data) => {
    setIsShowReport(data);
  };

  return (
    <>
      <Header displayReport={displayReport} />

      {!isShowReport && (
        <div>
          <AcademyQuiz />
        </div>
      )}
      {isShowReport && (
        <div>
          <Report />
        </div>
      )}
    </>
  );
}

export default AcademyDashboard;
