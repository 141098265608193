import React, { useState, useEffect } from "react";
import { Dropdown, Row, Col, Card, Button } from "react-bootstrap";
import "../../Styles/academyQuiz.css";
import Vector from "../../Assets/Images/Vector.png";
import edit from "../../Assets/Images/edit.svg";
import AddQuiz from "./AddQuiz";
import { quizList } from "../../apiClient";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function AcademyQuiz() {
  const [isShowAddQuiz, setIsShowAddQuiz] = useState(false);
  const [quizDetailsData, setQuizDetailsData] = useState({});
  const [showCopied, setShowCopied] = useState(false);
  const [copiedCodeId, setCopiedCodeId] = useState("");
  const [showEditQuiz, setShowEditQuiz] = useState(false)
  const [quizIndex, setQuizIndex] = useState('')

  useEffect(() => {
    getQuizList();
  }, []);

  const navigate = useNavigate();

  const getQuizList = () => {
    const dataToPost = {
      searchText: "",
    };
    quizList(dataToPost).then((res) => setQuizDetailsData(res.data));
  };

  const redirectToDetails = (id) => {
    navigate(`/academy/quiz/questionSet/${id}`);
  };

  const notify = () => toast("Code copied to clipboard!");

  const copyQuizCode = (quizList) => {
    setCopiedCodeId(quizList?.id);
    setShowCopied(true);
    navigator.clipboard.writeText(quizList?.shareCode);
    notify();
    setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  const openEditQuiz = (id) => {
    setQuizIndex(id)
    setShowEditQuiz(true)
  }

  return (
    <>
      <div style={{ padding: "20px", backgroundColor: "#F6F6F6" }}>
        <Row md={12}>
          <Col md={4}>
            <Dropdown className="quizDropdown">
              <Dropdown.Toggle>All Quiz</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Option 1</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Option 3</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={6}>
            <div className="searchContainer">
              <img src={Vector} className="App-logo" alt="Vector" />
              <input
                style={{ backgroundColor: "#F6F6F6" }}
                id="searchInput"
                type="text"
                placeholder="Search for Quiz name"
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="searchButton">
              <button type="submit" onClick={() => setIsShowAddQuiz(true)}>
                <b>+ Add Quiz</b>
              </button>
            </div>
          </Col>
        </Row>
        {isShowAddQuiz && (
          <AddQuiz
            show={isShowAddQuiz}
            close={() => setIsShowAddQuiz(false)}
            getQuizList={getQuizList}
          />
        )}
      </div>

      <Row className="quizListOuter">
        {quizDetailsData?.data?.map((quizList, indx) => {
          return (
            <Col md="3" key={quizList?.id} style={{ marginBottom: "20px" }}>
              <Card
                style={{
                  height: "318px",
                  width: "280px",
                  borderRadius: "5%",
                  cursor: "pointer",
                }}
              >
                {quizList?.image == null ? (
                  <h6
                    style={{
                      minHeight: "50%",
                      minWidth: "100%",
                      position: "relative",
                      top: "50%",
                      left: "75%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={() => redirectToDetails(quizList?.id)}
                  >
                    Image not available
                  </h6>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img
                      src={edit}
                      style={{
                        zIndex: "1",
                        position: "relative",
                        float: "right",
                      }}
                      onClick={() => openEditQuiz(indx)}
                    />
                    <Card.Img
                      onClick={() => redirectToDetails(quizList?.id)}
                      variant="top"
                      src={quizList?.image}
                      style={{
                        position: "relative",
                        bottom: "25px",
                        height: "170px"
                      }}
                    />
                  </div>
                )}
                <Card.Body className="quizInfo">
                  <Card.Text>
                    <h6 onClick={() => redirectToDetails(quizList?.id)}>
                      {quizList?.name}
                    </h6>
                  </Card.Text>
                  {quizList?.type == "PRIVATE" && (
                    <div className="quizCardButton">
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "black" }}
                        onClick={() => copyQuizCode(quizList)}
                      >
                        {copiedCodeId == quizList?.id && showCopied
                          ? "Copied"
                          : "Share code"}
                      </Button>
                      {/* <Button
                        variant="primary"
                        style={{
                          backgroundColor: "#E9E9EB",
                          color: "black",
                          border: "1px solid #E9E9EB",
                        }}
                      >
                        Share link
                      </Button> */}
                    </div>
                  )}
                </Card.Body>
              </Card>
              {quizIndex == indx && showEditQuiz && (
                <AddQuiz
                  show={showEditQuiz}
                  close={() => setShowEditQuiz(false)}
                  quizList={quizList?.id}
                  getQuizList={getQuizList}
                />
              )}
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default AcademyQuiz;
