import React from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import closeBtn from "../../Assets/Images/closeBtn.png";
import addQuizLogo from "../../Assets/Images/addQuizLogo.png";
import attachFile from "../../Assets/Images/attachFile.png";
import { addAcademy } from "../../apiClient";
import { useState } from "react";
import "../../Styles/AdminDashboard.css";
import { toast } from "react-toastify";

function AddAcademy(props) {
  const [academyName, setAcademyName] = useState("");
  const [repName, setRepName] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [passwordData, setPassword] = useState("");

  const closeModal = () => {
    props?.close();
  };

  const addNewAcademy = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("academyName", academyName);
    bodyFormData.append("name", repName);
    bodyFormData.append("representedName", repName);
    bodyFormData.append("designation", designation);
    bodyFormData.append("emailId", email);
    bodyFormData.append("mobileNumber", phoneNo);
    bodyFormData.append("password", passwordData);

    addAcademy(bodyFormData)
      .then((res) => {
        closeModal();
      })
      .catch((err) => toast.error(err.message));
  };

  const handleAcademyName = (e) => {
    setAcademyName(e.target.value);
  };

  const handleRepName = (e) => {
    setRepName(e.target.value);
  };

  const handleDesignation = (e) => {
    setDesignation(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneNo = (e) => {
    setPhoneNo(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  //   const loadAndCloseModal = () => {
  //     closeModal();
  //     props?.getQuizList();
  //   }

  //   const handleFiles = (e) => {
  //     setIsFileData(e.target.files[0])
  //   }

  return (
    <>
      <Modal
        scrollable={true}
        {...props}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="addQuizModal"
      >
        <Modal.Body>
          <div className="closeBtn">
            <img
              src={closeBtn}
              className="App-logo"
              alt="closeBtn"
              onClick={closeModal}
            />
          </div>
          <div className="container">
            <div className="addAcademyHeader">
              <img src={addQuizLogo} className="App-logo" alt="addQuizLogo" />
              <span>Add Academy</span>
            </div>

            <div className="addAcademyForm">
              <h6>Academy Name</h6>
              <input type="text" onChange={(e) => handleAcademyName(e)} />
            </div>
            <div className="addAcademyForm">
              <h6>Represented Name</h6>
              <input type="text" onChange={(e) => handleRepName(e)} />
            </div>
            <div className="addAcademyForm">
              <h6>Designation</h6>
              <input type="text" onChange={(e) => handleDesignation(e)} />
            </div>
            <div className="addAcademyForm">
              <h6>Email Address</h6>
              <input type="email" onChange={(e) => handleEmail(e)} />
            </div>
            <div className="addAcademyForm">
              <h6>Password</h6>
              <input type="password" onChange={(e) => handlePassword(e)} />
            </div>
            <div className="addAcademyForm">
              <h6>Phone Number</h6>
              <input type="number" onChange={(e) => handlePhoneNo(e)} />
            </div>

            <div className="addQuizFormButton">
              <button
                type="submit"
                style={{ backgroundColor: "black" }}
                onClick={addNewAcademy}
              >
                <b>Save</b>
              </button>
              <button
                type="close"
                style={{ backgroundColor: "red" }}
                onClick={closeModal}
              >
                <b>Cancel</b>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddAcademy;
