import React, { useState } from "react";
import "../Styles/login.css";
import { Row, Col } from "react-bootstrap";
import Logo from "../Assets/Images/mQuiz-logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { baseurl } from "../apiClient";

function Login() {
  const [usernameData, setUsernameData] = useState("");
  const [passwordData, setPasswordData] = useState("");

  const navigate = useNavigate();

  const handleUsername = (e) => {
    setUsernameData(e.target.value);
  };

  const handlepassword = (e) => {
    setPasswordData(e.target.value);
  };

  const redirectToAcademy = () => {
    const postData = {
      emailId: usernameData,
      password: passwordData,
    };
    axios
      .post(`${baseurl}/signIn`, postData)
      .then((res) => {
        if (res.status == 200) {
          let user = localStorage.setItem(
            "UserData",
            JSON.stringify(res.data.data.token)
          );
          // let Role = localStorage.setItem(
          //   "UserRole",
          //   JSON.stringify(res.data.data.roleName)
          // );
          if (res.data.data.roleName == "Admin") {
            navigate("/academy/dashboard");
          }
        } else {
          setUsernameData("");
          setPasswordData("");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="loginComponent">
      <Row md={12}>
        <Col md={6}></Col>
        <Col md={6}>
          <Row>
            <Col>
              <div className="loginSection">
                <div className="loginSection-header">
                  <h1>
                    Welcome to <b>QuizSurveys</b>
                  </h1>
                </div>
                <div className="quizLogo">
                  <img src={Logo} className="App-logo" alt="logo" />
                </div>
                <div className="loginSection-subHeader">
                  <h1>
                    Sign in to <b>Admin</b>
                  </h1>
                </div>
                <div className="loginForm">
                  <div className="usernameAndPasswordForm">
                    <h6>Username</h6>
                    <input
                      type="text"
                      placeholder="Enter User Name"
                      onChange={(e) => handleUsername(e)}
                    />
                  </div>
                  <div className="usernameAndPasswordForm">
                    <h6>Password</h6>
                    <input
                      type="password"
                      placeholder="Enter Password"
                      onChange={(e) => handlepassword(e)}
                    />
                  </div>
                  <div className="usernameAndPasswordFormButton">
                    <button type="submit" onClick={redirectToAcademy}>
                      <b>Submit</b>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <div>
            @test
            </div>
            
          </Row> */}
        </Col>
      </Row>
    </div>
  );
}

export default Login;
